import type { DoorFilters } from "./DoorType"

const form = {
  id: 0,
  door_no: "",
  door_desc: "",
  terminal_id: "",
}

const buildFilters = (newValues: Partial<DoorFilters> = {}): DoorFilters => ({
  search: "",
  order_by: "id",
  status_code: "",
  asc_or_desc: "desc",
  truck_name: "",
  trailer_name: "",
  driver_name: "",
  per_page: 0,
  page: 0,
  terminal_id: "",
  terminal_name: "",
  ...newValues,
})

const filters = buildFilters({
  order_by: "id",
  asc_or_desc: "desc",
  per_page: 100,
  page: 1,
})

export default {
  form,
  filters,
  buildFilters,
}
