import { useEffect, useRef, useState } from "react"
import { CgSearch } from "react-icons/cg"

import type { TTruck } from "@pages/Trips/TripType"
import type { Truck } from "@pages/Trucks/TruckType"
import { Button, ErrorMessage, Input, Table } from "@/@core"
import { useTrucks } from "@pages/Trucks/hooks/useTrucks"
import useClickOutside from "@/hooks/useClickOutside"
import { Load } from "../LoadType"
import { useLoads } from "../hooks/useLoads"
import Swal from "sweetalert2"
import { CONFIG_MAIN_TOAST, SwalConfig } from "@/constants"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"

interface Props {
  load: Load
  onUnassignedTruck(): void
  onAssignedTruck(): void
}

export const AssignTruck = ({ load, onAssignedTruck, onUnassignedTruck, ...inputProps }: Props) => {
  const { formatMessage: $t } = useIntl()
  const { debounceFetchAll, trucks, setTrucks, loading } = useTrucks()
  const { assign, unassign } = useLoads()
  const [error, setError] = useState<string>("")
  const [search, setSearch] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const inputSearchRef = useRef<HTMLDivElement | null>(null)
  const [currentTruck, setCurrentTruck] = useState<Truck>()
  const assignedTrucks = load.trip_info.filter((c) => !!c.trucks.length).flatMap((c) => c.trucks.map((x) => ({ ...x, tx_type: c.tx_type, tripId: c.id }))) ?? []

  useClickOutside(inputSearchRef, () => open && setOpen(false))

  const handleSelectClick = (value: Truck) => {
    setCurrentTruck(value)
    setSearch(value.name)
    setOpen(false)
  }

  useEffect(() => {
    if (!!search) debounceFetchAll({ per_page: 20, search, status_code: "AVAIL" })
    if (!search.length) setTrucks([])
    return () => debounceFetchAll.cancel()
  }, [search])

  const getTripNumber = (): { pickup: number; delivery: number } => {
    if (!load.trip_number) return { pickup: 0, delivery: 0 }
    const pickup = load.trip_number.find((c) => c.type === "PICKUP")?.trip_number ?? 0
    const delivery = load.trip_number.find((c) => c.type === "DELIVERY")?.trip_number ?? 0
    return { pickup, delivery }
  }

  const handleAssignDriverClick = async () => {
    try {
      if (!currentTruck) return
      const { pickup } = getTripNumber()
      const { data } = await assign({
        freights_ids: [load.id],
        trip_id: pickup,
        truck_ids: [currentTruck.id],
      })
      if (!data.status) throw new Error(data.msg)
      setSearch("")
      onAssignedTruck()
      toast.success($t({ id: "Process done correctly!" }), CONFIG_MAIN_TOAST)
      setTrucks([])
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const handleUnassignClick = async (truck: TTruck) => {
    try {
      const text = $t({ id: "Are you want to unassign?" }, { input: $t({ id: "Truck" }) })
      const { isConfirmed } = await Swal.fire(SwalConfig.questionUnassigned({ text, trans: $t }))
      if (!isConfirmed) return
      const { data } = await unassign({
        freights_ids: [load.id],
        trip_id: truck.tripId,
        truck_ids: [truck.id],
      })
      if (!data.status) throw new Error(data.msg)
      onUnassignedTruck()
      toast.success($t({ id: "Process done correctly!" }), CONFIG_MAIN_TOAST)
    } catch (error) {}
  }

  const BtnActions = (
    <Button type="button" color="success" onClick={handleAssignDriverClick} className="rounded-s-none">
      <span className="text-xs">{$t({ id: "Assign" })}</span>
    </Button>
  )

  return (
    <div>
      {!assignedTrucks.length && (
        <div className="flex flex-col mb-2">
          <div className="flex justify-between items-center">
            <span className="text-xs text-slate-600 dark:text-slate-400">
              {$t({ id: "Assign" })} {$t({ id: "Trucks" })}
            </span>
          </div>
          <div className="relative" ref={inputSearchRef}>
            <Input
              placeholder={$t({ id: "Search" })}
              fontSize="text-xs"
              IconStart={CgSearch}
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              ButtonEnd={BtnActions}
              onFocus={() => setOpen(true)}
              onClick={() => setOpen(true)}
              {...inputProps}
            />
            {open && (
              <>
                {loading ? (
                  <div className="bg-white z-50 w-full flex flex-col border rounded overflow-y-auto overflow-x-hidden max-h-[250px] scroll__styled absolute top-full dark:bg-slate-800 dark:text-slate-300 dark:border-slate-700">
                    <div className="p-2 text-center">
                      <span className="text-xs text-gray-400">{$t({ id: "Loading" })}</span>
                    </div>
                  </div>
                ) : (
                  <div className="bg-white z-50 w-full flex flex-col border rounded overflow-y-auto overflow-x-hidden max-h-[250px] scroll__styled absolute top-full dark:bg-slate-800 dark:text-slate-300 dark:border-slate-700">
                    {trucks.length ? (
                      trucks.map((item, key) => (
                        <button
                          key={key}
                          role="button"
                          type="button"
                          onClick={() => handleSelectClick(item)}
                          className="flex-1 w-full bg-white hover:bg-gray-100 dark:hover:bg-slate-700 p-1 text-xs text-start cursor-pointer relative"
                        >
                          <p className="text-blue-700 capitalize dark:text-blue-600 font-bold">{item.name}</p>
                          <p className="text-gray-400 absolute top-1 right-1">
                            {[`Model: ${item.model || ""}`, `Plate: ${item.plate || ""}`, `Year: ${item.year || ""}`].join(" / ")}
                          </p>
                        </button>
                      ))
                    ) : (
                      <div className="p-2 text-center text-xs text-gray-400">
                        <span>{$t({ id: "No options" })}</span>
                        {!!search.length && (
                          <span>
                            {" "}
                            {$t({ id: "for" })} <span className="text-gray-500">"{search}"</span>
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <ErrorMessage error={error} />
        </div>
      )}
      <Table.Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{$t({ id: "Truck" })}</Table.Th>
            <Table.Th className="w-[80px] max-w-[80px]">
              <p className="text-center">{$t({ id: "Options" })}</p>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {!assignedTrucks.length ? (
            <Table.Tr>
              <Table.Td colSpan={2}>
                <div className="text-center py-2">
                  <img src="/images/box-empty.png" className="h-14 mx-auto" alt="Icon Box Empty" />
                  <p className="block text-slate-600 dark:text-slate-400">{$t({ id: "No records" })}</p>
                </div>
              </Table.Td>
            </Table.Tr>
          ) : (
            assignedTrucks.map((item, index) => (
              <Table.Tr key={index}>
                <Table.Td>
                  <div className="flex flex-col text-slate-600 dark:text-slate-300">
                    <p className="text-primary font-semibold capitalize">{item.name}</p>
                    <p>
                      {$t({ id: "Make" })}: <span className="text-gray-400 dark:text-slate-400">{item.make ?? "-"}</span>
                    </p>
                    <p>
                      {$t({ id: "Model" })}: <span className="text-gray-400 dark:text-slate-400">{item.model ?? "-"}</span>
                    </p>
                    <p>
                      {$t({ id: "Plate" })}: <span className="text-gray-400 dark:text-slate-400">{item.plate ?? "-"}</span>
                    </p>
                    <p>
                      {$t({ id: "Year" })}: <span className="text-gray-400 dark:text-slate-400">{item.year ?? "-"}</span>
                    </p>
                    <p>
                      {$t({ id: "VIN" })}: <span className="text-gray-400 dark:text-slate-400">{item.VIN ?? "-"}</span>
                    </p>
                    <p>
                      {item.model} {item.plate} {item.year}
                    </p>
                  </div>
                </Table.Td>
                <Table.Td>
                  <Button color="danger" icon onClick={() => handleUnassignClick(item)}>
                    <span className="text-xs">{$t({ id: "Unassign" })}</span>
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))
          )}
        </Table.Tbody>
      </Table.Table>
      {!!assignedTrucks.length && (
        <p className="text-xs text-slate-600 dark:text-slate-400 mt-3">
          {assignedTrucks.length} {$t({ id: "Records Found" })}
        </p>
      )}
    </div>
  )
}
