import { ILoadToTable } from "@/pages/DispatchPage/DispatchType"
import type { Load, TLoadActions, TripNumber } from "@/pages/Loads/LoadType"
import type { Trip } from "@/pages/Trips/TripType"
import { Adapter } from "@pages/Loads/LoadAdapter"
import { cloneDeep } from "lodash"

export const loadActionsTypes: TLoadActions = {
  LOAD_FREIGHTS: (state, newFreights) => {
    const { perPages, currentPage } = state
    const totalPages = Math.ceil(newFreights.length / perPages)
    const freights = newFreights.map(Adapter.LoadToTable)
    const freightsPaginated = freights.slice((currentPage - 1) * perPages, currentPage * perPages)
    return {
      ...state,
      freightsPaginated,
      currentPage: 1,
      totalPages,
      freights,
    }
  },
  ADD_FREIGHT_TO_LIST(state, payload) {
    if (!state.freights.length) {
      return {
        ...state,
        freights: [],
      }
    }
    return {
      ...state,
      freights: [...state.freights, Adapter.LoadToTable(payload)],
    }
  },
  UPDATE_FREIGHT_TO_LIST(state, payload) {
    const freights = [...state.freights]
    const index = freights.findIndex((c) => c.id == payload.id)
    if (index >= 0) {
      freights.splice(index, 1, Adapter.LoadToTable(payload))
    }
    return { ...state, freights }
  },
  REMOVE_FREIGHT_TO_LIST(state, payload) {
    return {
      ...state,
      freights: state.freights.filter((c) => c.id !== payload.id),
    }
  },
  ADD_DOCUMENT_TO_FREIGHT(state, payload) {
    const { freight, documents } = payload
    const freights = cloneDeep(state.freights)
    const index = freights.findIndex((c) => c.id == freight.id)
    if (index >= 0) {
      freights[index].documents.push(...documents)
    }
    if (state.freightSelected?.id == freight.id) {
      const currentFreightSelected = { ...state.freightSelected }
      currentFreightSelected.documents.push(...documents)
      return {
        ...state,
        freights,
        freightSelected: currentFreightSelected,
      }
    }
    return {
      ...state,
      freights,
    }
  },
  SET_FREIGHT_SELECTED(state, freightSelected) {
    return { ...state, freightSelected }
  },
  TOGGLE_ADD_TRIP_INFO_TO_FREIGHT(state, { type, trip, freightIds }) {
    const { freights, freightSelected } = state
    const typeComplete = type == "D" ? "DELIVERY" : "PICKUP"
    const isDelivery = type == "D"
    const newTrip: Trip = { ...trip, tx_type: type }
    const newTripNumber: TripNumber = { trip_number: newTrip.id, type: typeComplete }
    let newFreightSelected: Load | undefined
    if (freightSelected) {
      newFreightSelected = cloneDeep(freightSelected)
      newFreightSelected = toggleAssignFreightToTrip({ freight: Adapter.LoadToTable(freightSelected), isDelivery, newTrip, newTripNumber, typeComplete })
    }
    const newFreights = cloneDeep(freights).map((freight) => {
      if (Array.isArray(freightIds)) {
        if (freightIds.includes(freight.id)) {
          freight = toggleAssignFreightToTrip({ freight, isDelivery, newTrip, newTripNumber, typeComplete })
        }
      } else if (freight.id == freightIds) {
        freight = toggleAssignFreightToTrip({ freight, isDelivery, newTrip, newTripNumber, typeComplete })
      }
      return Adapter.LoadToTable(freight)
    })
    return {
      ...state,
      freights: newFreights,
      freightSelected: newFreightSelected,
    }
  },
  UPDATE_FILTERS(state, newFilters) {
    return {
      ...state,
      freightFilters: {
        ...state.freightFilters,
        ...newFilters,
      },
    }
  },
  UPDATE_PREV_FILTERS(state, newFilters) {
    return {
      ...state,
      prevFreightFilters: {
        ...state.prevFreightFilters,
        ...newFilters,
      },
    }
  },
  UPDATE_PAGINATION(state, newPagination) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...newPagination,
      },
    }
  },
}

interface TToggleAssignFreightToTripParams {
  freight: ILoadToTable
  typeComplete: "DELIVERY" | "PICKUP"
  newTripNumber: TripNumber
  isDelivery: boolean
  newTrip: Trip
}

const toggleAssignFreightToTrip = (data: TToggleAssignFreightToTripParams): ILoadToTable => {
  const { freight, typeComplete, isDelivery, newTrip, newTripNumber } = data
  const tripNumbers = freight.trip_number || []
  const assigned = tripNumbers.some((c) => c.type == typeComplete)
  const newTripInfo = freight.trip_info.filter((c) => !!c.tx_type)
  if (!assigned) {
    if (isDelivery) {
      newTripInfo.push(newTrip)
      tripNumbers.push(newTripNumber)
    } else {
      newTripInfo.unshift(newTrip)
      tripNumbers.unshift(newTripNumber)
    }
  } else {
    if (isDelivery) {
      newTripInfo.pop()
      tripNumbers.pop()
    } else {
      newTripInfo.shift()
      tripNumbers.shift()
    }
  }
  freight.trip_info = newTripInfo
  freight.trip_number = tripNumbers
  return freight
}
