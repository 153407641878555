import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from "react"
import type { TPageDB } from "@/router/type"
import type { IResponseUserConfig, ISocketContext } from "./types"
import { SocketContext } from "./SocketContext"
import { TTableLoads, TTableFormLoad } from "@/pages/Loads/Load.columns"
import * as initialStateLoadColumns from "@/pages/Loads/Load.columns"
import { mergeColumns } from "@/pages/DispatchPage/utils"
import { getUserFromLocalStorage } from "./AuthenticateContext"

interface IColumnsContext {
  loadColumns(page: TPageDB): void
  columnsLoads: Column[]
  columnsLoadsDetailsForm: Column[]
  setColumnsLoads: Dispatch<SetStateAction<Column[]>>
  setColumnsLoadsDetailsForm: Dispatch<SetStateAction<Column[]>>
  handleSortColumnTableChange: ISocketContext["handleSortColumnTableChange"]
}

interface OnGetStoredColumns {
  (payload: any): void
}

export const ColumnContext = createContext<IColumnsContext>({} as IColumnsContext)

const ColumnContextProvider = ({ children }: { children: ReactNode }) => {
  const auth: User = getUserFromLocalStorage()
  const currentPage = useRef<TPageDB>()
  const synchronizedPages = useRef<TPageDB[]>([])
  const [columnsLoads, setColumnsLoads] = useState<Column[]>([])
  const [columnsLoadsDetailsForm, setColumnsLoadsDetailsForm] = useState<Column[]>([])
  const { socket, handleSortColumnTableChange, isConnected } = useContext(SocketContext)
  const waitingSocketConnectionTimeout = useRef<NodeJS.Timeout>()

  const syncPage = () => {
    if (!currentPage.current) return
    synchronizedPages.current.push(currentPage.current)
  }

  const upColumns = (page: TPageDB, storedColumns: Column[] = []) => {
    switch (page) {
      case "LOADS":
        {
          if (!!columnsLoads.length) return
          const newColumns = mergeColumns(initialStateLoadColumns.COLUMNS_LOADS, storedColumns)
          setColumnsLoads(newColumns)
        }
        break
      case "FORM_LOAD":
        {
          if (!!columnsLoadsDetailsForm.length) return
          const newColumns = mergeColumns(initialStateLoadColumns.COLUMNS_DETAILS, storedColumns)
          setColumnsLoadsDetailsForm(newColumns)
        }
        break
      default:
        break
    }
  }

  const onGetStoredColumns: OnGetStoredColumns = (originPayload: any) => {
    if (waitingSocketConnectionTimeout.current) {
      clearTimeout(waitingSocketConnectionTimeout.current)
    }
    if (!currentPage.current || synchronizedPages.current.includes(currentPage.current)) return
    syncPage()
    if (currentPage.current === "FORM_LOAD") {
      const payload: IResponseUserConfig<TTableFormLoad> = originPayload as IResponseUserConfig<TTableFormLoad>
      upColumns("FORM_LOAD", payload?.tableColumns?.DETAILS || [])
    } else if (currentPage.current === "LOADS") {
      const payload: IResponseUserConfig<TTableLoads> = originPayload as IResponseUserConfig<TTableLoads>
      upColumns("LOADS", payload?.tableColumns?.MAIN || [])
    }
    currentPage.current = undefined
  }

  useEffect(() => {
    if (socket && isConnected) {
      socket.on("get-stored-columns", onGetStoredColumns)
    }
    return () => {
      socket?.off("get-stored-columns", onGetStoredColumns)
    }
  }, [socket, isConnected])

  const loadColumns = useCallback(
    (page: TPageDB) => {
      try {
        currentPage.current = page
        if (isConnected) {
          socket?.emit("get-stored-columns", { companyId: auth.company_id, userId: auth.id, page })
        } else {
          upColumns(page)
        }
      } catch (error) {}
    },
    [socket, isConnected],
  )

  const share = {
    loadColumns,
    columnsLoads,
    setColumnsLoads,
    columnsLoadsDetailsForm,
    setColumnsLoadsDetailsForm,
    handleSortColumnTableChange,
    socket,
  }

  return <ColumnContext.Provider value={share}>{children}</ColumnContext.Provider>
}

export default ColumnContextProvider
