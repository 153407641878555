import { FaPaperPlane } from "react-icons/fa"
import { useContext, useState } from "react"
import { InitState, useLoads } from "../hooks/useLoads"
import { FormikHelpers, useFormik } from "formik"
import { AxiosError } from "axios"
import clsx from "clsx"
import * as Yup from "yup"
import { useIntl } from "react-intl"
import { format } from "date-fns"

import { LoadContext } from "../context/LoadContext"
import { Button, ErrorMessage, Input, Required, SelectSearch, SuperInput } from "@/@core"
import type { IFormUpdateStatus, Load } from "../LoadType"
import laravelErrors from "@/utils/laravel.errors"
import { SocketContext } from "@/context/SocketContext"
import { AppContext } from "@/context/AppContext"

interface Props {
  initState: IFormUpdateStatus | undefined
  onCancel: () => void
  onSuccess: (record: Load) => void
}

export const LoadFormUpdateStatus = ({ onCancel, initState, onSuccess }: Props): JSX.Element => {
  const { formatMessage: $t } = useIntl()
  const { status, currentLoad } = useContext(LoadContext)
  const { emitEvent, isConnected } = useContext(SocketContext)
  const { dispatchLoads } = useContext(AppContext)
  const { updateStatus } = useLoads()
  const [error, setError] = useState<string>("")

  const messageRequired = $t({ id: "this field is required" })

  const onSubmit = async (values: IFormUpdateStatus, actions: FormikHelpers<IFormUpdateStatus>) => {
    actions.setSubmitting(true)
    setError("")
    try {
      const { data: resp } = await updateStatus(values)
      const { data, status, msg } = resp
      if (!status) {
        if (typeof msg === "string") return setError(msg!)
        const _errors = laravelErrors.toObject(msg!)
        setErrors(_errors)
      } else {
        if (isConnected) {
          emitEvent("update-freight", data)
          dispatchLoads({ type: "UPDATE_FREIGHT_TO_LIST", payload: data })
        }
        onSuccess(data)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.response?.data?.error)
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  const validationSchema = Yup.object().shape({
    status_id: Yup.number().min(1, messageRequired).required(messageRequired),
    date_time_status: Yup.date().required(messageRequired),
  })

  const { values, handleChange, handleSubmit, errors, setErrors, isSubmitting, setFieldValue } = useFormik<IFormUpdateStatus>({
    initialValues: initState ?? { ...InitState.formUpdateStatus, date_time_status: format(new Date(), "yyyy-MM-dd'T'HH:mm") },
    validationSchema,
    validateOnChange: false,
    onSubmit,
  })

  const availableStatus = status.filter((c) => c.freight)

  return (
    <form className="w-full flex flex-col gap-2" onSubmit={handleSubmit} noValidate autoComplete="off">
      <ErrorMessage error={error} />
      <div className="rounded bg-emerald-500/10 text-emerald-500 text-center py-4">
        <h3 className="font-bold text-xs">{currentLoad?.bill_number || "-"}</h3>
        <h3 className="font-bold text-2xl">{currentLoad?.status?.status_code || "-"}</h3>
      </div>
      <div className="flex flex-col">
        <label htmlFor="status_id" className="text-xs">
          {$t({ id: "New" })} {$t({ id: "Status" })} <Required />
        </label>
        <SelectSearch
          options={availableStatus}
          renderLabel={(item) => item.status_code}
          onChange={(item) => setFieldValue("status_id", item.id)}
          initSearch={""}
          position="to-bottom"
        >
          {(option) => (
            <SelectSearch.Option key={crypto.randomUUID().toString()} data={option}>
              {option.status_code}
            </SelectSearch.Option>
          )}
        </SelectSearch>
        <ErrorMessage error={errors.status_id} />
      </div>
      <div className="flex flex-col">
        <SuperInput
          id="date_time_status"
          type="datetime-local"
          label={`${$t({ id: "Date" })} / ${$t({ id: "Time" })}`}
          value={values.date_time_status}
          onChange={handleChange}
          error={errors.date_time_status}
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="comments" className="text-xs">
          {$t({ id: "Comment" })}
        </label>
        <Input id="comments" type="comments" onChange={handleChange} value={values.comments} />
        <ErrorMessage error={errors.comments} />
      </div>
      <div className="flex justify-end gap-2 items-center mt-4">
        <Button type="button" color="light" onClick={onCancel}>
          {$t({ id: "Cancel" })}
        </Button>
        <Button type="submit" color="primary" className={clsx(isSubmitting && "disabled:cursor-not-allowed disabled:opacity-90")} disabled={isSubmitting}>
          <FaPaperPlane />
          {$t({ id: "Update" })}
        </Button>
      </div>
    </form>
  )
}
