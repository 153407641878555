import { useEffect, useRef, useState } from "react"
import { CgSearch } from "react-icons/cg"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"

import type { Driver } from "@pages/Drivers/DriverType"
import type { TDriver } from "@pages/Trips/TripType"
import { Button, ErrorMessage, Input, Table } from "@/@core"
import { useDrivers } from "@pages/Drivers/hooks/useDrivers"
import useClickOutside from "@/hooks/useClickOutside"
import { Load } from "../LoadType"
import { useLoads } from "../hooks/useLoads"
import { CONFIG_MAIN_TOAST, SwalConfig } from "@/constants"

interface Props {
  load: Load
  onUnassignedDriver(): void
  onAssignedDriver(): void
}

interface TOptions {
  id: string
  label: string
}

const options: TOptions[] = [
  {
    id: "0",
    label: "Pickup & Delivery",
  },
  {
    id: "P",
    label: "Pickup",
  },
  {
    id: "D",
    label: "Delivery",
  },
]

export const AssignDriver = ({ load, onUnassignedDriver, onAssignedDriver, ...inputProps }: Props) => {
  const { formatMessage: $t } = useIntl()
  const { debounceFetchAll, drivers, setDrivers, loading } = useDrivers()
  const { assign, unassign } = useLoads()
  const [error, setError] = useState<string>("")
  const [search, setSearch] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const inputSearchRef = useRef<HTMLDivElement | null>(null)
  const [currentDriver, setCurrentDriver] = useState<Driver>()
  const assignedDrivers =
    load.trip_info.filter((c) => !!c.drivers.length).flatMap((c) => c.drivers.map((x) => ({ ...x, tx_type: c.tx_type, tripId: c.id }))) ?? []

  const getLabel = (id: string): string => {
    return options.find((c) => c.id === id)?.label ?? ""
  }

  useClickOutside(inputSearchRef, () => open && setOpen(false))

  const handleSelectClick = (value: Driver) => {
    setCurrentDriver(value)
    setSearch(value.name)
    setOpen(false)
  }

  useEffect(() => {
    if (!!search) debounceFetchAll({ per_page: 20, search, status_code: "AVAIL" })
    if (!search.length) setDrivers([])
    return () => debounceFetchAll.cancel()
  }, [search])

  const getTripNumber = (): { pickup: number; delivery: number } => {
    if (!load.trip_number) return { pickup: 0, delivery: 0 }
    const pickup = load.trip_number.find((c) => c.type === "PICKUP")?.trip_number ?? 0
    const delivery = load.trip_number.find((c) => c.type === "DELIVERY")?.trip_number ?? 0
    return { pickup, delivery }
  }

  const handleAssignDriverClick = async () => {
    try {
      if (!currentDriver) return
      const { pickup } = getTripNumber()
      const { data } = await assign({
        trip_id: pickup,
        driver_ids: [currentDriver.id],
        freights_ids: [load.id],
      })
      if (!data.status) throw new Error(data.msg)
      setSearch("")
      onAssignedDriver()
      toast.success($t({ id: "Process done correctly!" }), CONFIG_MAIN_TOAST)
      setDrivers([])
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const handleUnassignClick = async (driver: TDriver) => {
    try {
      const text = $t({ id: "Are you want to unassign?" }, { input: $t({ id: "Driver" }) })
      const { isConfirmed } = await Swal.fire(SwalConfig.questionUnassigned({ text, trans: $t }))
      if (!isConfirmed) return
      const { data } = await unassign({
        trip_id: driver.tripId,
        driver_ids: [driver.id],
        freights_ids: [load.id],
      })
      if (!data.status) throw new Error(data.msg)
      onUnassignedDriver()
      toast.success($t({ id: "Process done correctly!" }), CONFIG_MAIN_TOAST)
    } catch (error) {}
  }

  const BtnActions = (
    <Button type="button" color="success" onClick={handleAssignDriverClick} className="rounded-s-none">
      <span className="text-xs">{$t({ id: "Assign" })}</span>
    </Button>
  )

  return (
    <div>
      {!assignedDrivers.length && (
        <div className="flex flex-col mb-2">
          <div className="flex justify-between items-center">
            <span className="text-xs text-slate-600 dark:text-slate-400">
              {$t({ id: "Assign" })} {$t({ id: "Drivers" })}
            </span>
          </div>
          <div className="relative" ref={inputSearchRef}>
            <Input
              placeholder={$t({ id: "Search" })}
              fontSize="text-xs"
              IconStart={CgSearch}
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              ButtonEnd={BtnActions}
              onFocus={() => setOpen(true)}
              onClick={() => setOpen(true)}
              {...inputProps}
            />
            {open && (
              <>
                {loading ? (
                  <div className="bg-white z-50 w-full flex flex-col border rounded overflow-y-auto overflow-x-hidden max-h-[250px] scroll__styled absolute top-full dark:bg-slate-800 dark:text-slate-300 dark:border-slate-700">
                    <div className="p-2 text-center">
                      <span className="text-xs text-gray-400">{$t({ id: "Loading" })}</span>
                    </div>
                  </div>
                ) : (
                  <div className="bg-white z-50 w-full flex flex-col border rounded overflow-y-auto overflow-x-hidden max-h-[250px] scroll__styled absolute top-full dark:bg-slate-800 dark:text-slate-300 dark:border-slate-700">
                    {drivers.length ? (
                      drivers.map((item, key) => (
                        <button
                          key={key}
                          role="button"
                          type="button"
                          onClick={() => handleSelectClick(item)}
                          className="flex-1 w-full hover:bg-gray-100 dark:hover:bg-slate-700 p-1 text-xs text-start cursor-pointer relative"
                        >
                          <p className="text-blue-700 capitalize dark:text-blue-600 font-bold">{item.name}</p>
                          <p className="text-gray-400">{item.email}</p>
                          <p className="text-gray-400 absolute top-1 right-1">{[item.city, item.state, item.zip_code].filter(Boolean).join(" / ")}</p>
                        </button>
                      ))
                    ) : (
                      <div className="p-2 text-center text-xs text-gray-400">
                        <span>{$t({ id: "No options" })}</span>
                        {!!search.length && (
                          <span>
                            {" "}
                            {$t({ id: "for" })} <span className="text-gray-500">"{search}"</span>
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <ErrorMessage error={error} />
        </div>
      )}
      <Table.Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              <p className="text-slate-600 dark:text-slate-400">{$t({ id: "Driver" })}</p>
            </Table.Th>
            <Table.Th>
              <p className="text-center text-slate-600 dark:text-slate-400">{$t({ id: "Type" })}</p>
            </Table.Th>
            <Table.Th className="w-[80px] max-w-[80px]">
              <p className="text-center text-slate-600 dark:text-slate-400">{$t({ id: "Options" })}</p>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {!assignedDrivers.length ? (
            <Table.Tr>
              <Table.Td colSpan={3}>
                <div className="text-center py-2">
                  <img src="/images/box-empty.png" className="h-14 mx-auto" alt="Icon Box Empty" />
                  <p className="block text-slate-600 dark:text-slate-400">{$t({ id: "No records" })}</p>
                </div>
              </Table.Td>
            </Table.Tr>
          ) : (
            assignedDrivers.map((item, index) => (
              <Table.Tr key={index}>
                <Table.Td>
                  <div className="flex flex-col">
                    <p className="text-primary font-semibold capitalize">{item.name}</p>
                    <p className="text-slate-600 dark:text-slate-300">
                      {$t({ id: "Email" })}: <span className="text-gray-400 dark:text-slate-400">{item.email ?? "-"}</span>
                    </p>
                    <p className="text-slate-600 dark:text-slate-300">
                      {$t({ id: "License" })}: <span className="text-gray-400 dark:text-slate-400">{item.driver_license ?? "-"}</span>
                    </p>
                    <p className="text-slate-600 dark:text-slate-300">
                      {$t({ id: "Phone" })}: <span className="text-gray-400 dark:text-slate-400">{item.phone_number ?? "-"}</span>
                    </p>
                    <p className="text-slate-600 dark:text-slate-300">
                      {$t({ id: "Address 1" })}: <span className="text-gray-400 dark:text-slate-400">{item.address_1 ?? "-"}</span>
                    </p>
                    <p className="text-slate-600 dark:text-slate-300">
                      {$t({ id: "Address 2" })}: <span className="text-gray-400 dark:text-slate-400">{item.address_2 ?? "-"}</span>
                    </p>
                    <p className="text-slate-600 dark:text-slate-300">
                      {item.city} {item.state} {item.zip_code}
                    </p>
                  </div>
                </Table.Td>
                <Table.Td>
                  <p className="text-center text-slate-600 dark:text-slate-300">{$t({ id: getLabel(item.tx_type) })}</p>
                </Table.Td>
                <Table.Td>
                  <Button color="danger" icon onClick={() => handleUnassignClick(item)}>
                    <span className="text-xs">{$t({ id: "Unassign" })}</span>
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))
          )}
        </Table.Tbody>
      </Table.Table>
      {!!assignedDrivers.length && (
        <p className="text-xs text-slate-600 dark:text-slate-400 mt-3">
          {assignedDrivers.length} {$t({ id: "Records Found" })}
        </p>
      )}
    </div>
  )
}
