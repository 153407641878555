import React from "react"
import clsx from "clsx"

type Colors = "primary" | "secondary" | "dark" | "warning" | "danger" | "success" | "light" | "default" | "info"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  children?: React.ReactNode
  icon?: boolean
  color?: Colors
  type?: "submit" | "button" | "reset"
  tooltip?: string
  tooltipId?: "t4tms-tooltip" | "t4-tooltip-modal"
  tooltipPlace?:
    | "top"
    | "top-start"
    | "top-end"
    | "right"
    | "right-start"
    | "right-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "left"
    | "left-start"
    | "left-end"
}

export const Button = ({
  children,
  className,
  color = "default",
  icon = false,
  type = "button",
  tooltipId = "t4tms-tooltip",
  tooltip,
  tooltipPlace = "top",
  ...props
}: Props) => {
  const _color = {
    primary: "border-blue-600 bg-blue-600 [&:not(:disabled)]:hover:bg-blue-700 text-white",
    success: "border-emerald-500 bg-emerald-500 [&:not(:disabled)]:hover:bg-emerald-600 text-white",
    secondary: "border-gray-500 bg-gray-500 [&:not(:disabled)]:hover:bg-gray-600 text-white",
    dark: "border-gray-800 bg-gray-800 [&:not(:disabled)]:hover:bg-gray-900 text-white dark:hover:bg-gray-700 dark:hover:disabled:bg-gray-800",
    info: "border-cyan-600 bg-cyan-600 [&:not(:disabled)]:hover:bg-cyan-700 text-white",
    warning: "border-yellow-400 bg-yellow-400 [&:not(:disabled)]:hover:bg-yellow-500 dark:text-slate-700",
    danger: "border-red-600 bg-red-600 [&:not(:disabled)]:hover:bg-red-800 text-white",
    light: "border-gray-300 bg-transparent [&:not(:disabled)]:hover:bg-white/5 text-gray-700 dark:text-slate-300 dark:border-slate-700 dark:hover:bg-slate-800",
    default: "border-blue-900 bg-blue-900 [&:not(:disabled)]:hover:bg-blue-950 text-white",
  }[color]

  const mergeClasses = `${className} z-10 inline-flex border rounded focus:ring-2 text-xs focus:ring-offset-1 dark:focus:border-slate-800 outline-none sm:text-sm items-center gap-1`

  const attrsTooltip: Record<string, string> = {}

  if (tooltip) {
    attrsTooltip["data-tooltip-id"] = tooltipId
    attrsTooltip["data-tooltip-content"] = tooltip
    attrsTooltip["data-tooltip-place"] = tooltipPlace
  }

  return (
    <button
      className={clsx(mergeClasses, icon ? "p-1.5 px-2.5" : "px-4 py-2", _color, props.disabled && "cursor-not-allowed disabled:opacity-60")}
      type={type}
      {...attrsTooltip}
      {...props}
    >
      {children}
    </button>
  )
}
