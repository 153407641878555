import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react"
import { AxiosResponse } from "axios"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import { cloneDeep } from "lodash"

import type {
  IDispatchContext,
  DYNAMIC_SPACES,
  LOADING_TYPES,
  FilterFreightBills,
  ILoadToTable,
  PayloadBulkAssign,
  TableNames,
  QueuePromises,
  IFreightBillToTable,
  IPayloadGlobalFilter,
  IKeys,
  FilterOrder,
  IAssignUnassignFreightToTrip,
  TFreights,
  ILoadFreightsPayload,
  ILoaders,
  IChangeLoaderPayload,
  IAddRemoveFreightToListPayload,
  TUpFilter,
  IDayFilters,
} from "@pages/DispatchPage/DispatchType"
import type { IResponseUserConfig, ResponseUserConfigExpress } from "@/context/types"
import type { Freight, PayloadToggleAssign, PayloadToggleAssignToServer, ResponseTrip, Trip, TripFilters } from "@pages/Trips/TripType"
import type { Load, LoadFilters } from "@/pages/Loads/LoadType"
import type { DoorFilters } from "@/pages/Doors/DoorType"
import { useLoads, InitState as InitStateLoad } from "@pages/Loads/hooks/useLoads"
import { useTrips, InitState as InitStateTrip, PayloadToggleDispatch } from "@pages/Trips/hooks/useTrips"
import { useDoors, InitState as InitStateDoor } from "@pages/Doors/hooks/useDoors"
import { useDrivers, InitState as InitStateDriver } from "@/pages/Drivers/hooks/useDrivers"
import { useTrucks, InitState as InitStateTruck } from "@/pages/Trucks/hooks/useTrucks"
import { useTrailers, InitState as InitStateTrailer } from "@/pages/Trailers/hooks/useTrailers"
import { CONFIG_MAIN_TOAST } from "@/constants"
import { useStatus } from "@/pages/Status/hooks/useStatus"
import { useTerminals } from "@/pages/Terminals/hooks/useTerminals"
import { KEYS, REORDER_COLUMNS, TABLE_COLUMNS } from "../constants"
import { useEQClasses } from "@/pages/EQClasses/hooks/useEQClasses"
import { mergeColumns } from "../utils"
import InitialState from "@pages/DispatchPage/DispatchInitial.state"
import { Driver } from "@/pages/Drivers/DriverType"
import { Trailer } from "@/pages/Trailers/TrailerType"
import { Truck } from "@/pages/Trucks/TruckType"
import { calculateDirections } from "@/utils/apiGoogle"
import { useUsers } from "@/pages/Users/hooks/useUsers"
import UserInitialState from "@/pages/Users/UserInitial.state"
import DispatchAdapter from "../dispatch.adapter"
import { SocketContext } from "@/context/SocketContext"
import { getUserFromLocalStorage } from "@/context/AuthenticateContext"
import { NAME_PAGES } from "@/router/routeInitial.state"
import { useCarriers, InitialState as InitialStateCarrier } from "@/pages/Carriers/hooks/useCarriers"
import { Carrier } from "@/pages/Carriers/CarrierType"
import { AppContext } from "@/context/AppContext"
import { Adapter as LoadAdapter } from "@pages/Loads/LoadAdapter"

export const FILTER_TYPES: Array<"Delivery" | "Pickup"> = ["Pickup", "Delivery"]
export const DispatchContext = createContext({} as IDispatchContext)
export const MAX_BULK_SELECTED_ITEMS: number = 10
export const RUN_QUEUE_DELAY_IN_SECONDS: number = 3600
const initSearchHistoryValues = {
  order_by: "",
  asc_or_desc: "",
}
export const initialFiltersLoads = { ...InitStateLoad.filters, per_page: 1000, order_by: "bill_number", asc_or_desc: "desc", type: FILTER_TYPES[0] }
const DispatchContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const { handleSortColumnTableChange, socket, emitBulkUpdateColumnsQueue, emitBulkUpdateWithoutDelay, isConnected, emitEvent } = useContext(SocketContext)
  const { storeDispatch, dispatchDispatch } = useContext(AppContext)
  const { tripSelected, terminalSelectedId } = storeDispatch
  const Auth: User = getUserFromLocalStorage()
  const { formatMessage: $t } = useIntl()
  const [loading, setLoading] = useState<LOADING_TYPES>()
  const [dynamicSpace, setDynamicSpace] = useState<DYNAMIC_SPACES>("Loads")
  const [searchTerminalId, setSearchTerminalId] = useState<ModelID>(0)
  const [searchLoad, setSearchLoad] = useState<string>("")
  const [searchTrip, setSearchTrip] = useState<string>("")
  const [selectedTerminal, setSelectedTerminal] = useState<Terminal>({} as Terminal)
  const { fetchAll: fetchStatus, status: allStates } = useStatus()
  const { fetchAll: fetchTerminals, terminals, loading: loadingTerminals, setTerminals } = useTerminals()
  const { fetchAll: fetchEQClasses, EQClasses, setEQClasses } = useEQClasses()
  const [loadingFreightBills, setLoadingFreightBills] = useState<boolean>(false)
  const [showModalUpdateColumn, setShowModalUpdateColumn] = useState<TableNames | undefined>(undefined)
  const [listColumnsToUpdate, setListColumnsToUpdate] = useState<Column[]>([])
  const [modalAssign, setModalAssign] = useState<string>("")
  const [tabTripIndex, setTabTripIndex] = useState<number | string>(0)
  const [tabActionIndex, setTabActionIndex] = useState<number | string>(1)
  const [tabResourcesIndex, setTabResourcesIndex] = useState<number | undefined>(0)
  const [filterFreightBills, setFilterFreightBills] = useState<FilterFreightBills>(() => InitialState.filterFreightBills)
  // 👉 Globals
  const [canChangeOrder, setCanChangeOrder] = useState<boolean>(false)
  const { filtersLoads } = storeDispatch
  const [loaders, setLoaders] = useState<ILoaders>(InitialState.loaders)
  const [bulkAssignmentFreights, setBulkAssignmentFreights] = useState<boolean>(false)
  const changeLoader = ({ prop, newValue }: IChangeLoaderPayload) => {
    setLoaders((prev) => ({ ...prev, [prop]: newValue }))
  }
  // 👉 Freights
  const { fetchAll: fetchFreights, findOne: findOneFreight } = useLoads()
  const [freights, setFreights] = useState<TFreights>({ pickups: [], deliveries: [] })
  const currentProcessingGetFreights = useRef<boolean>(false)

  const loadFreights = async ({ type, refresh = false }: ILoadFreightsPayload) => {
    if (currentProcessingGetFreights.current) return
    currentProcessingGetFreights.current = true
    try {
      if (!refresh) {
        if (type === "Pickup" && !!storeDispatch.freights.pickups.length) return
        else if (type === "Delivery" && !!storeDispatch.freights.deliveries.length) return
      }
      changeLoader({ prop: "freights", newValue: true })
      const response = await fetchFreights({ ...filtersLoads, type })
      const payload = { refresh, type, newLoads: response.freights }
      dispatchDispatch({ type: "SET_FREIGHTS", payload })
    } catch (error) {
    } finally {
      currentProcessingGetFreights.current = false
      changeLoader({ prop: "freights", newValue: false })
    }
  }
  const addRemoveFreightToList = (payload: IAddRemoveFreightToListPayload) => {
    dispatchDispatch({ type: "TOGGLE_ADD_TO_FREIGHTS", payload })
    emitEvent("assign-or-unassign-to-trip", { ...payload, TYPE_ACTION: "TOGGLE_ADD_TO_FREIGHTS" })
  }
  const terminalFilters = {
    terminal_id: String(Auth.terminal?.id || 0),
    terminal_name: Auth.terminal?.name,
  }
  // 👉 Doors
  const [searchDoor, setSearchDoor] = useState<string>("")
  const [filtersDoors, setFiltersDoors] = useState<DoorFilters>(InitStateDoor.buildFilters())
  const [prevFiltersDoors, setPrevFiltersDoors] = useState<DoorFilters>(InitStateDoor.filters)
  const {
    fetchAll: fetchDoors,
    debounceFetchAll: debounceFetchAllDoors,
    doors,
    setDoors,
    pagination: paginationDoors,
    loading: loadingDoors,
  } = useDoors(filtersDoors)
  const handleChangePageDoors = (page: number) => setFiltersDoors((prev) => ({ ...prev, page }))
  // 👉 Trips
  const [filtersTrips, setFiltersTrips] = useState<TripFilters>(InitStateTrip.buildFilters({ per_page: 1000, page: 1 }))
  const [prevFiltersTrips, setPrevFiltersTrips] = useState<TripFilters>(InitStateTrip.filters)
  const {
    fetchAll: fetchTrips,
    trips,
    store: storeTrip,
    setTrips,
    findById: findTripById,
    assign,
    unassign,
    assignDispatch,
    unassignDispatch,
    pagination: paginationTrips,
    loading: loadingTrips,
    fetchStatusHistory: fetchTripsStatusHistory,
    updateRouteSequence,
    loadingMiles,
    recalculateMiles,
  } = useTrips(filtersTrips)
  const handleChangePageTrips = (page: number) => dispatchDispatch({ type: "SET_FILTER_TRIPS", payload: { page } })
  const loadTrips = async () => {
    try {
      const response = await fetchTrips(storeDispatch.filtersTrips)
      dispatchDispatch({ type: "SET_TRIPS", payload: response })
    } catch (error) {}
  }
  // 👉 Loads
  const [, setFiltersLoads] = useState<LoadFilters>(InitStateLoad.buildFilters())
  const [dayFilters, setDayFilters] = useState<IDayFilters>()
  const {
    fetchAll: fetchLoads,
    debounceFetchAll: debounceFetchAllLoads,
    loads,
    pagination: paginationLoads,
    loading: loadingLoads,
    fetchStatusHistory: fetchLoadsStatusHistory,
  } = useLoads(filtersLoads)
  const handleChangePageLoads = (page: number) => dispatchDispatch({ type: "SET_FILTER_LOADS", payload: { page } })
  const [bulkAssignFreights, setBulkAssignFreights] = useState<PayloadBulkAssign[]>([])
  const handleClickWithKeyControl = (index: number, data: ILoadToTable) => {
    const existsInList = bulkAssignFreights.findIndex((c) => c.index === index)
    if (existsInList !== -1) {
      setBulkAssignFreights((prev) => {
        const newGroup = [...prev]
        newGroup.splice(existsInList, 1)
        return newGroup
      })
    } else if (bulkAssignFreights.length < MAX_BULK_SELECTED_ITEMS - 1) {
      setBulkAssignFreights((prev) => {
        const newGroup = [...prev]
        newGroup.push({ data, index })
        return newGroup
      })
    }
  }
  // Dispatches
  const [filtersDispatches, setFiltersDispatches] = useState<LoadFilters>({ ...UserInitialState.filters, order_by: "name", per_page: 1000 })
  const {
    fetchAll: fetchDispatches,
    debounceFetchAll: debounceFetchDispatches,
    users: dispatches,
    pagination: paginationDispatches,
    loading: loadingDispatches,
  } = useUsers(filtersDispatches)
  const handleChangePageDispatches = (page: number) => setFiltersDispatches((prev) => ({ ...prev, page }))
  // 👉 Drivers
  const [filtersDrivers, setFiltersDrivers] = useState<TableFilters>({ ...InitStateDriver.filters, per_page: 1000 })
  const {
    fetchAll: fetchDrivers,
    debounceFetchAll: debounceFetchAllDrivers,
    drivers,
    pagination: paginationDrivers,
    loading: loadingDrivers,
    fetchStatusHistory: fetchDriverStatusHistory,
  } = useDrivers(filtersDrivers)
  const handleChangePageDrivers = (page: number) => setFiltersDrivers((prev) => ({ ...prev, page }))
  // 👉 Trucks
  const [filtersTrucks, setFiltersTrucks] = useState<TableFilters>({ ...InitStateTruck.filters, order_by: "name", per_page: 1000 })
  const {
    fetchAll: fetchTrucks,
    debounceFetchAll: debounceFetchAllTrucks,
    trucks,
    pagination: paginationTrucks,
    loading: loadingTrucks,
    fetchStatusHistory: fetchTruckStatusHistory,
  } = useTrucks(filtersTrucks)
  const handleChangePageTrucks = (page: number) => setFiltersTrucks((prev) => ({ ...prev, page }))
  // 👉 Trailers
  const [filtersTrailers, setFiltersTrailers] = useState<TableFilters>({ ...InitStateTrailer.filters, order_by: "name", per_page: 1000 })
  const {
    fetchAll: fetchTrailers,
    debounceFetchAll: debounceFetchAllTrailers,
    trailers,
    pagination: paginationTrailers,
    loading: loadingTrailers,
    fetchStatusHistory: fetchTrailerStatusHistory,
  } = useTrailers(filtersTrailers)
  const handleChangePageTrailers = (page: number) => setFiltersTrailers((prev) => ({ ...prev, page }))
  // 👉 Carriers
  const [filtersCarriers, setFiltersCarriers] = useState<TableFilters>({ ...InitialStateCarrier.filters, per_page: 1000 })
  const { fetchAll: fetchCarriers, carriers, loading: loadingCarriers, setCarriers } = useCarriers(filtersCarriers)

  const [queuePromises, setQueuePromises] = useState<QueuePromises[]>([])
  const preventContextMenu = (ev: any) => ev.preventDefault()

  const assignUnassignFreightToTrip = async (data: IAssignUnassignFreightToTrip) => {
    const { tripId, type, freightIds, isAssign } = data
    try {
      const payload = buildPayload({
        dynamicKey: "freights_ids",
        modelId: freightIds,
        tripId,
        type,
      })
      await handlerRequest(payload, isAssign ? assign : unassign, null)
    } catch (error) {
      throw error
    }
  }

  async function dragTripToLoadsToggle(
    currentRecord: Freight | Load,
    isAssign: boolean = true,
    _type: string = filtersLoads.type === FILTER_TYPES[0] ? "D" : "P",
    updatedList: boolean = true,
  ) {
    try {
      const payload = buildPayload({
        tripId: tripSelected!.id,
        dynamicKey: "freights_ids",
        modelId: [currentRecord.id],
        type: _type,
      })
      await handlerRequest(payload, isAssign ? assign : unassign)
      if (dynamicSpace === "Loads" && updatedList) fetchLoads()
    } catch (error) {}
  }

  const assignAndShowMessagePerOne = async (data: IAssignUnassignFreightToTrip, currentFreight: Load): Promise<void> => {
    changeLoader({ prop: "unassigningFreight", newValue: true })
    assignUnassignFreightToTrip(data)
      .then(async () => {
        const messageAssigned = $t({ id: "the freight BillNumber has been successfully unassigned" }, { billNumber: currentFreight.bill_number })
        toast.success(messageAssigned, CONFIG_MAIN_TOAST)
        const response = await findOneFreight(currentFreight.id.toString(), { type: currentFreight.tx_type === "P" ? "Pickup" : "Delivery" })
        const { status, data } = response.data
        if (status) {
          const newItem = LoadAdapter.loadToLoadWithType(data, currentFreight.tx_type)
          addRemoveFreightToList({ type: currentFreight.tx_type === "P" ? "Pickup" : "Delivery", freightId: newItem.id, action: "ADD", newItem })
          updateTripFreight(tripSelected!.id)
        }
      })
      .finally(() => changeLoader({ prop: "unassigningFreight", newValue: false }))
  }

  const [queueDelayInSeconds, setQueueDelayInSeconds] = useState<string>(String(RUN_QUEUE_DELAY_IN_SECONDS))
  const [timeLeft, setTimeLeft] = useState<number>(Number(queueDelayInSeconds))
  const togglePromises = (data: QueuePromises) => {
    setQueuePromises((prev) => {
      const { ID, callback } = data
      const index = prev.findIndex((item) => item.ID === ID)
      if (index === -1) {
        prev.push({ ID, callback })
      } else {
        prev.splice(index, 1)
      }
      return prev
    })
  }

  useEffect(() => {
    const __interval__ = setInterval(
      () => {
        if (queuePromises.length) {
          for (const { callback } of queuePromises) {
            if (callback) callback()
          }
        }
        setTimeLeft(Number(queueDelayInSeconds) + 1)
      },
      Number(queueDelayInSeconds) * 1000,
    )
    setTimeLeft(Number(queueDelayInSeconds))
    const __tracking_seconds_interval__ = setInterval(() => {
      setTimeLeft((prev) => prev - 1)
    }, 1000)
    return () => {
      clearInterval(__interval__)
      clearInterval(__tracking_seconds_interval__)
    }
  }, [queuePromises, queueDelayInSeconds])
  // @ts-ignore
  const buildPayload = (payload: PayloadToggleAssign): PayloadToggleAssignToServer => {
    const { tripId, modelId, type = "", dynamicKey, carrierId, ...restPayload } = payload
    const newPayload: PayloadToggleAssignToServer = { trip_id: tripId }
    if (carrierId) {
      newPayload.carrier_id = carrierId
      return { ...newPayload, ...restPayload }
    }
    // @ts-ignore
    newPayload[dynamicKey] = modelId
    return {
      ...newPayload,
      type,
    }
  }

  const updateTripWithNewData = (data: Trip) => {
    handleRenderFreightsClick(data, filterFreightBills)
  }

  const handlerRequest = async (
    payload: any,
    callback: (payload: any) => Promise<AxiosResponse<ResponseTrip>>,
    text: string | null = "Successful process",
  ): Promise<void> => {
    try {
      const response = await callback(payload)
      const { status, msg, data } = response.data
      if (status) {
        // @ts-ignore
        if (status == "Freight not have avail status") {
          // @ts-ignore
          toast.error(msg ?? $t({ id: `${status}` }), CONFIG_MAIN_TOAST)
          throw { status, msg }
        }
        if ("freights_ids" in payload) {
          emitEvent("assign-or-unassign-to-trip", {
            freightIds: payload.freights_ids,
            TYPE_ACTION: "FREIGHTS",
            type: payload.type,
            trip: data,
          })
        }
        if (tripSelected?.id == data.id) {
          dispatchDispatch({
            type: "SET_TRIP_SELECTED",
            payload: { ...data, freights: tripSelected!.freights },
          })
        }
        emitEvent("update-trip", { ...data, TYPE_ACTION: "LIST" })
        dispatchDispatch({ type: "UPDATE_TRIPS", payload: data })
        if (text) toast.success($t({ id: text }), CONFIG_MAIN_TOAST)
      } else {
        // @ts-ignore
        toast.error(msg ?? status, CONFIG_MAIN_TOAST)
      }
    } catch (error) {
      throw error
    }
  }

  const createTrip = async () => {
    try {
      changeLoader({ prop: "creatingTrip", newValue: true })
      const { data: resp } = await storeTrip({ terminal_id: terminalSelectedId })
      const { data, status } = resp
      if (status) {
        const payload = { TYPE_ACTION: "ADD", data }
        dispatchDispatch({ type: "TOGGLE_ADD_TRIP_TO_TRIPS", payload })
        dispatchDispatch({ type: "SET_TRIP_SELECTED_FOR_ME", payload })
        dispatchDispatch({ type: "CURRENT_TRIP_SELECTED_ID", payload: data.id })
        emitEvent("create-trip", payload)
      }
    } catch (error) {
      throw error
    } finally {
      changeLoader({ prop: "creatingTrip", newValue: false })
      setLoading(undefined)
    }
  }

  useEffect(() => {
    fetchStatus()
    fetchTerminals()
  }, [])

  const status = useMemo(() => allStates.filter((c) => c.freight), [allStates])
  const statusTrips = useMemo(() => allStates.filter((c) => c.trip), [allStates])
  const driverStates = useMemo(() => allStates.filter((c) => c.driver), [allStates])
  const trucksStates = useMemo(() => allStates.filter((c) => c.truck), [allStates])
  const trailersStates = useMemo(() => allStates.filter((c) => c.trailer), [allStates])

  const [tableColumnSizes, setTableColumnSizes] = useState<ResponseUserConfigExpress["tableColumnsSizes"]>()
  const [tableSizes, setTableSizes] = useState<ResponseUserConfigExpress["tableSizes"]>()
  const [columnsLoads, setColumnsLoads] = useState<Column[]>([])
  const [columnsLoadsPickups, setColumnsLoadsPickups] = useState<Column[]>([])
  const [columnsLoadsDeliveries, setColumnsLoadsDeliveries] = useState<Column[]>([])
  const [columnsDrivers, setColumnsDrivers] = useState<Column[]>([])
  const [columnsTrucks, setColumnsTrucks] = useState<Column[]>([])
  const [columnsTrailers, setColumnsTrailers] = useState<Column[]>([])
  const [columnsFreightBills, setColumnsFreightBills] = useState<Column[]>([])
  const [columnsStatusHistoryTrips, setColumnsStatusHistoryTrips] = useState<Column[]>([])
  const [columnsStatusHistoryDrivers, setColumnsStatusHistoryDrivers] = useState<Column[]>([])
  const [columnsStatusHistoryTrucks, setColumnsStatusHistoryTrucks] = useState<Column[]>([])
  const [columnsStatusHistoryTrailers, setColumnsStatusHistoryTrailers] = useState<Column[]>([])
  const [columnsDispatches, setColumnsDispatches] = useState<Column[]>([])
  const [columnsStatusHistoryFreights, setColumnsStatusHistoryFreights] = useState<Column[]>([])
  const [columnsTrips, setColumnsTrips] = useState<Column[]>([])
  const [columnsDoors, setColumnsDoors] = useState<Column[]>([])
  const [columnsCarriers, setColumnsCarriers] = useState<Column[]>([])

  const [currentTripSelectedId, setCurrentTripSelectedId] = useState<ModelID>(0)

  const setState = (key: REORDER_COLUMNS, columns?: Column[]) => {
    const hasColumns = !!columns
    switch (key) {
      case REORDER_COLUMNS.DISPATCHES:
        {
          const cols = TABLE_COLUMNS.COLUMNS_DISPATCHES
          setColumnsDispatches(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.DOORS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_DOORS
          setColumnsDoors(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.DRIVERS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_DRIVERS
          setColumnsDrivers(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.FREIGHT_BILLS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_FREIGHT_BILLS
          setColumnsFreightBills(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.LOADS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_LOADS_DELIVERIES
          setColumnsLoads(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.LOADS_DELIVERIES:
        {
          const cols = TABLE_COLUMNS.COLUMNS_LOADS_DELIVERIES
          setColumnsLoadsDeliveries(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.LOADS_PICKUPS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_LOADS_PICKUPS
          setColumnsLoadsPickups(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.STATUS_HISTORY_DRIVERS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_STATUS_HISTORY_DRIVERS
          setColumnsStatusHistoryDrivers(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.STATUS_HISTORY_FREIGHTS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_STATUS_HISTORY_FREIGHTS
          setColumnsStatusHistoryFreights(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.STATUS_HISTORY_TRAILERS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_STATUS_HISTORY_TRAILERS
          setColumnsStatusHistoryTrailers(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.STATUS_HISTORY_TRIPS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_STATUS_HISTORY_TRIPS
          setColumnsStatusHistoryTrips(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.STATUS_HISTORY_TRUCKS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_STATUS_HISTORY_TRUCKS
          setColumnsStatusHistoryTrucks(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.TRAILERS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_TRAILERS
          setColumnsTrailers(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.TRIPS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_TRIPS
          setColumnsTrips(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.TRUCKS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_TRUCKS
          setColumnsTrucks(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      case REORDER_COLUMNS.CARRIERS:
        {
          const cols = TABLE_COLUMNS.COLUMNS_CARRIERS
          setColumnsCarriers(() => (hasColumns ? mergeColumns(cols, cloneDeep(columns)) : cols))
        }
        break
      default:
        break
    }
  }

  const [filterTripHistory, setFilterTripHistory] = useState<FilterOrder>({ ...initSearchHistoryValues })
  const [filterDriverHistory, setFilterDriverHistory] = useState<FilterOrder>({ ...initSearchHistoryValues })
  const [filterTrailerHistory, setFilterTrailerHistory] = useState<FilterOrder>({ ...initSearchHistoryValues })
  const [filterTruckHistory, setFilterTruckHistory] = useState<FilterOrder>({ ...initSearchHistoryValues })
  const [tabActionHistoryIndex, setTabActionHistoryIndex] = useState<number>(0)

  const mountedTabLoadRef = useRef<HTMLDivElement | null>(null)
  const mountedTabTripRef = useRef<HTMLDivElement | null>(null)
  const [synchronized, setSynchronized] = useState<boolean>(false)
  // 👉 Set initial columns
  useEffect(() => {
    const setInitialStateColumns = (tableColumnsStored: ResponseUserConfigExpress["tableColumns"]) => {
      if (!tableColumnsStored) return
      for (const _key of Object.values(REORDER_COLUMNS)) {
        if (!!tableColumnsStored[_key]) {
          setState(_key, tableColumnsStored[_key])
        } else {
          setState(_key)
        }
      }
    }
    const setInitialFilters = (payload: IResponseUserConfig<keyof IKeys>["tableFilters"]) => {
      if (payload.TRIPS_FILTERS) {
        const newFilters = { ...payload.TRIPS_FILTERS }
        if (newFilters.terminal_id == "") newFilters.terminal_id = "0"
        setFiltersTrips(cloneDeep(newFilters))
        dispatchDispatch({ type: "SET_FILTER_TRIPS", payload: newFilters })
        setSearchTerminalId(newFilters.terminal_id)
        dispatchDispatch({ type: "SET_TERMINAL_SELECTED_ID", payload: newFilters.terminal_id })
      } else {
        upInitialFilter("TRIPS")
      }
      if (!!payload.FREIGHTS_FILTERS) {
        const newFilters = { ...payload.FREIGHTS_FILTERS }
        if (newFilters.terminal_id == "") newFilters.terminal_id = "0"
        dispatchDispatch({ type: "SET_FILTER_LOADS", payload: cloneDeep(newFilters) })
        setSearchTerminalId(newFilters.terminal_id)
        dispatchDispatch({ type: "SET_TERMINAL_SELECTED_ID", payload: newFilters.terminal_id })
      } else {
        upInitialFilter("FREIGHTS")
      }
      if (payload.DOORS_FILTERS) {
        setFiltersDoors(payload.DOORS_FILTERS)
      } else {
        upInitialFilter("DOORS")
      }
      if (payload.RUN_QUEUE_DELAY_IN_SECONDS) setQueueDelayInSeconds(payload.RUN_QUEUE_DELAY_IN_SECONDS)
      if (payload.SELECTED_TERMINAL_ID) {
        setSearchTerminalId(payload.SELECTED_TERMINAL_ID)
        dispatchDispatch({ type: "SET_TERMINAL_SELECTED_ID", payload: payload.SELECTED_TERMINAL_ID })
      }
      if (payload.TRIPS_SEARCH) setSearchTrip(payload.TRIPS_SEARCH)
      if (payload.TAB_TRIPS_INDEX) setTabTripIndex(payload.TAB_TRIPS_INDEX)
      if (payload.TAB_ACTION_INDEX) setTabActionIndex(payload.TAB_ACTION_INDEX)
      if (payload.TAB_RESOURCES_INDEX) setTabResourcesIndex(Number(payload.TAB_RESOURCES_INDEX))
      if (payload.CURRENT_TRIP_SELECTED_ID) {
        setCurrentTripSelectedId(payload.CURRENT_TRIP_SELECTED_ID)
        dispatchDispatch({ type: "CURRENT_TRIP_SELECTED_ID", payload: payload.CURRENT_TRIP_SELECTED_ID })
      }
      if (payload.FREIGHT_BILLS_FILTERS) setFilterFreightBills(payload.FREIGHT_BILLS_FILTERS)
      if (payload.FREIGHT_BILLS_SEARCH) setFiltersSectionFreightBills(payload.FREIGHT_BILLS_SEARCH)
      if (payload.TRIPS_HISTORY_FILTERS) setFilterTripHistory(payload.TRIPS_HISTORY_FILTERS)
      if (payload.DRIVERS_HISTORY_FILTERS) setFilterDriverHistory(payload.DRIVERS_HISTORY_FILTERS)
      if (payload.TRAILERS_HISTORY_FILTERS) setFilterTrailerHistory(payload.TRAILERS_HISTORY_FILTERS)
      if (payload.TRUCKS_HISTORY_FILTERS) setFilterTruckHistory(payload.TRUCKS_HISTORY_FILTERS)
      if (payload.TAB_ACTION_HISTORY_INDEX) setTabActionHistoryIndex(payload.TAB_ACTION_HISTORY_INDEX)
      if (payload.FREIGHTS_SEARCH) setSearchLoad(payload.FREIGHTS_SEARCH)
    }
    const onGetStoredColumns = (payload: any) => {
      setInitialStateColumns(payload?.tableColumns ?? {})
      if (payload?.tableColumnsSizes) setTableColumnSizes(payload.tableColumnsSizes)
      if (payload?.tableSizes) setTableSizes(payload.tableSizes)
      if (payload?.tableFilters) {
        setInitialFilters(payload.tableFilters)
      } else {
        upInitialFilter("TRIPS")
        upInitialFilter("FREIGHTS")
        upInitialFilter("DOORS")
        setSearchTerminalId(Number(Auth.terminal?.id))
        dispatchDispatch({ type: "SET_TERMINAL_SELECTED_ID", payload: Number(Auth.terminal?.id) })
      }
      setSynchronized(true)
    }
    if (isConnected && socket) {
      upInitialFilter("TRIPS")
      upInitialFilter("FREIGHTS")
      upInitialFilter("DOORS")
      // @ts-ignore
      setInitialStateColumns({})
      socket?.emit("get-stored-columns", {
        companyId: Auth.company_id,
        userId: Auth.id,
        page: NAME_PAGES.DISPATCH,
      })
    } else {
      upInitialFilter("TRIPS")
      upInitialFilter("FREIGHTS")
      upInitialFilter("DOORS")
      // @ts-ignore
      setInitialStateColumns({})
    }
    socket?.on("get-stored-columns", onGetStoredColumns)
    socket?.on("connect_error", () => {
      onGetStoredColumns({})
    })
    return () => {
      socket?.off("get-stored-columns", onGetStoredColumns)
    }
  }, [socket, isConnected])

  const upInitialFilter = (type: TUpFilter) => {
    const initValues = {
      ...terminalFilters,
      per_page: 1000,
    }
    switch (type) {
      case "TRIPS":
        dispatchDispatch({ type: "SET_FILTER_TRIPS", payload: { ...InitStateTrip.filters, ...initValues } })
        break
      case "FREIGHTS":
        dispatchDispatch({
          type: "SET_FILTER_LOADS",
          payload: {
            ...InitStateLoad.filters,
            ...initValues,
            type: "Pickup",
          },
        })
        break
      case "DOORS":
        setFiltersDoors({ ...InitStateDoor.filters, ...initValues })
        break
      default:
        break
    }
  }

  const handleRenderFreightsClick = async (value: Trip, filters?: FilterFreightBills, restrictSelection: boolean = false) => {
    if (restrictSelection && value.id === tripSelected?.id) return
    try {
      setLoadingFreightBills(true)
      const response = await findTripById(value.id, filters)
      const { data } = response.data
      if (response.status) {
        dispatchDispatch({
          type: "SET_TRIP_SELECTED_FOR_ME",
          payload: data,
        })
        dispatchDispatch({ type: "UPDATE_TRIPS", payload: data })
        emitEvent("update-trip", { TYPE_ACTION: "LIST", ...data })
        emitEvent("update-trip", { TYPE_ACTION: "CURRENT_SELECTED", ...data })
      }
    } catch (error) {
    } finally {
      setLoadingFreightBills(false)
    }
  }

  async function assignToggleCarrier(carrier: Carrier, isAssign: boolean = true, data?: any): Promise<void> {
    try {
      const payload = buildPayload({
        tripId: tripSelected!.id,
        carrierId: carrier.id,
        ...data,
      })
      await handlerRequest(payload, isAssign ? assign : unassign)
    } catch (error) {}
  }

  async function assignToggleDispatch(dispatch: User, isAssign: boolean = true): Promise<void> {
    try {
      const payload: PayloadToggleDispatch = {
        tripId: tripSelected!.id,
        dispatchId: dispatch.id,
      }
      await handlerRequest(payload, isAssign ? assignDispatch : unassignDispatch)
    } catch (error) {}
  }

  async function assignToggleDriver(driver: Driver, isAssign = true): Promise<void> {
    const payload = buildPayload({
      tripId: tripSelected!.id,
      dynamicKey: "driver_ids",
      modelId: [driver.id],
    })
    await handlerRequest(payload, isAssign ? assign : unassign)
    if (dynamicSpace === "Drivers") fetchDrivers()
  }

  async function assignToggleTrailer(trailer: Trailer, isAssign = true): Promise<void> {
    const payload = buildPayload({
      tripId: tripSelected!.id,
      dynamicKey: "trailer_ids",
      modelId: [trailer.id],
    })
    await handlerRequest(payload, isAssign ? assign : unassign)
    if (dynamicSpace === "Trailers") fetchTrailers()
  }

  async function assignToggleTruck(truck: Truck, isAssign = true): Promise<void> {
    const payload = buildPayload({
      tripId: tripSelected!.id,
      dynamicKey: "truck_ids",
      modelId: [truck.id],
    })
    await handlerRequest(payload, isAssign ? assign : unassign)
    if (dynamicSpace === "Trucks") fetchTrucks()
  }

  const handleUpdateColumnsClick = (tableName: TableNames) => {
    let values: Column[] = []
    if (tableName === "DispatchTripsTrips") {
      values = cloneDeep(columnsTrips)
    } else if (tableName === "DispatchTripsDoors") {
      values = cloneDeep(columnsDoors)
    } else if (tableName === "DispatchResourcesFreights") {
      values = cloneDeep(columnsLoads)
    } else if (tableName === "DispatchResourcesFreightsPickups") {
      values = cloneDeep(columnsLoadsPickups)
    } else if (tableName === "DispatchResourcesFreightsDeliveries") {
      values = cloneDeep(columnsLoadsDeliveries)
    } else if (tableName === "DispatchResourcesTrucks") {
      values = cloneDeep(columnsTrucks)
    } else if (tableName === "DispatchResourcesDrivers") {
      values = cloneDeep(columnsDrivers)
    } else if (tableName === "DispatchResourcesTrailers") {
      values = cloneDeep(columnsTrailers)
    } else if (tableName === "DispatchActionsFreightBills") {
      values = cloneDeep(columnsFreightBills)
    } else if (tableName === "DispatchActionsStatusHistoryTrips") {
      values = cloneDeep(columnsStatusHistoryTrips)
    } else if (tableName === "DispatchActionsStatusHistoryDrivers") {
      values = cloneDeep(columnsStatusHistoryDrivers)
    } else if (tableName === "DispatchActionsStatusHistoryTrailers") {
      values = cloneDeep(columnsStatusHistoryTrailers)
    } else if (tableName === "DispatchActionsStatusHistoryTrucks") {
      values = cloneDeep(columnsStatusHistoryTrucks)
    } else if (tableName === "DispatchActionsStatusHistoryFreights") {
      values = cloneDeep(columnsStatusHistoryFreights)
    } else if (tableName === "DispatchResourcesDispatches") {
      values = cloneDeep(columnsDispatches)
    } else if (tableName === "DispatchResourcesCarriers") {
      values = cloneDeep(columnsCarriers)
    }
    setListColumnsToUpdate(values)
    setShowModalUpdateColumn(tableName)
  }

  const handlerUpdateColumns = (columns: Column[]) => {
    let UPDATE_COLUMNS
    if (showModalUpdateColumn === "DispatchTripsTrips") {
      UPDATE_COLUMNS = REORDER_COLUMNS.TRIPS
      setColumnsTrips(columns)
    } else if (showModalUpdateColumn === "DispatchTripsDoors") {
      UPDATE_COLUMNS = REORDER_COLUMNS.DOORS
      setColumnsDoors(columns)
    } else if (showModalUpdateColumn === "DispatchResourcesFreights") {
      UPDATE_COLUMNS = REORDER_COLUMNS.LOADS
      setColumnsLoads(columns)
    } else if (showModalUpdateColumn === "DispatchResourcesFreightsPickups") {
      UPDATE_COLUMNS = REORDER_COLUMNS.LOADS_PICKUPS
      setColumnsLoadsPickups(columns)
    } else if (showModalUpdateColumn === "DispatchResourcesFreightsDeliveries") {
      UPDATE_COLUMNS = REORDER_COLUMNS.LOADS_DELIVERIES
      setColumnsLoadsDeliveries(columns)
    } else if (showModalUpdateColumn === "DispatchResourcesTrucks") {
      UPDATE_COLUMNS = REORDER_COLUMNS.TRUCKS
      setColumnsTrucks(columns)
    } else if (showModalUpdateColumn === "DispatchResourcesDrivers") {
      UPDATE_COLUMNS = REORDER_COLUMNS.DRIVERS
      setColumnsDrivers(columns)
    } else if (showModalUpdateColumn === "DispatchResourcesTrailers") {
      UPDATE_COLUMNS = REORDER_COLUMNS.TRAILERS
      setColumnsTrailers(columns)
    } else if (showModalUpdateColumn === "DispatchActionsFreightBills") {
      UPDATE_COLUMNS = REORDER_COLUMNS.FREIGHT_BILLS
      setColumnsFreightBills(columns)
    } else if (showModalUpdateColumn === "DispatchActionsStatusHistoryTrips") {
      UPDATE_COLUMNS = REORDER_COLUMNS.STATUS_HISTORY_TRIPS
      setColumnsStatusHistoryTrips(columns)
    } else if (showModalUpdateColumn === "DispatchActionsStatusHistoryDrivers") {
      UPDATE_COLUMNS = REORDER_COLUMNS.STATUS_HISTORY_DRIVERS
      setColumnsStatusHistoryDrivers(columns)
    } else if (showModalUpdateColumn === "DispatchActionsStatusHistoryTrailers") {
      UPDATE_COLUMNS = REORDER_COLUMNS.STATUS_HISTORY_TRAILERS
      setColumnsStatusHistoryTrailers(columns)
    } else if (showModalUpdateColumn === "DispatchActionsStatusHistoryTrucks") {
      UPDATE_COLUMNS = REORDER_COLUMNS.STATUS_HISTORY_TRUCKS
      setColumnsStatusHistoryTrucks(columns)
    } else if (showModalUpdateColumn === "DispatchActionsStatusHistoryFreights") {
      UPDATE_COLUMNS = REORDER_COLUMNS.STATUS_HISTORY_FREIGHTS
      setColumnsStatusHistoryFreights(columns)
    } else if (showModalUpdateColumn === "DispatchResourcesDispatches") {
      UPDATE_COLUMNS = REORDER_COLUMNS.DISPATCHES
      setColumnsDispatches(columns)
    } else if (showModalUpdateColumn === "DispatchResourcesCarriers") {
      UPDATE_COLUMNS = REORDER_COLUMNS.CARRIERS
      setColumnsCarriers(columns)
    }
    handleSortColumnTableChange(UPDATE_COLUMNS!, columns)
    setShowModalUpdateColumn(undefined)
    setListColumnsToUpdate([])
  }

  const [showDirectionsInMap, setShowDirectionsInMap] = useState<boolean>(false)
  const [directionsOnTheMap, setDirectionsOnTheMap] = useState<google.maps.DirectionsResult>()
  const [waypoints, setWaypoints] = useState<IFreightBillToTable[]>([])
  const [creatingRoute, setCreatingRoute] = useState<boolean>(false)
  const [directionError, setDirectionError] = useState<string | undefined>()

  const renderDirectionDispatchFreightsBillsToMap = async () => {
    try {
      setCreatingRoute(true)
      setDirectionError(undefined)
      const normalized = tripSelected?.freights?.map(DispatchAdapter.FreightBillsToTable)
      let initCoords: google.maps.LatLng, endCoords: google.maps.LatLng
      if (tripSelected!.origin_zone?.latitude && tripSelected!.origin_zone?.longitude) {
        const { origin_zone } = tripSelected!
        initCoords = new google.maps.LatLng(Number(origin_zone!.latitude), Number(origin_zone!.longitude))
      } else {
        const [firstFreight] = normalized!
        if (!firstFreight) return
        initCoords = new google.maps.LatLng(Number(firstFreight.locLatitude), Number(firstFreight.locLongitude))
      }
      if (tripSelected!.destination_zone?.latitude && tripSelected!.destination_zone?.longitude) {
        const { destination_zone } = tripSelected!
        endCoords = new google.maps.LatLng(Number(destination_zone!.latitude), Number(destination_zone!.longitude))
      } else {
        const endFreight = normalized![normalized!.length - 1]
        if (!endFreight) return
        endCoords = new google.maps.LatLng(Number(endFreight.locLatitude), Number(endFreight.locLongitude))
      }
      setWaypoints(cloneDeep(normalized!))
      if (initCoords.equals(endCoords)) {
        if (normalized![normalized!.length - 1]) {
          const lastPosition = normalized!.pop()
          if (lastPosition) {
            endCoords = new google.maps.LatLng(Number(lastPosition.locLatitude), Number(lastPosition.locLongitude))
          }
        }
      }
      setShowDirectionsInMap(true)
      const route = await calculateDirections({
        origin: initCoords,
        destination: endCoords,
        points: normalized!.map((c) => ({ latitude: c.locLatitude, longitude: c.locLongitude })),
      })
      // @ts-ignore
      setDirectionsOnTheMap(route)
    } catch (error) {
      if (error instanceof google.maps.MapsRequestError) {
        setDirectionError(error.message)
      }
    } finally {
      setCreatingRoute(false)
    }
  }
  const [filtersSectionFreightBills, setFiltersSectionFreightBills] = useState<TableFilters>({ order_by: "", asc_or_desc: "" })
  const storeFilter = (payload: IPayloadGlobalFilter, withDelay = true) => {
    if (withDelay) {
      emitBulkUpdateColumnsQueue({
        page: "DISPATCH",
        type: "tableFilters",
        ...payload,
      })
    } else {
      emitBulkUpdateWithoutDelay({
        page: "DISPATCH",
        type: "tableFilters",
        ...payload,
      })
    }
  }

  const deselectedTrip = () => {
    dispatchDispatch({ type: "SET_TRIP_SELECTED_FOR_ME", payload: undefined })
    dispatchDispatch({ type: "CURRENT_TRIP_SELECTED_ID", payload: "" })
    storeFilter({ key: KEYS.CURRENT_TRIP_SELECTED_ID, payload: "" }, false)
  }

  const updateTripFreight = async (tripId: number): Promise<void> => {
    try {
      if (!!tripId) {
        changeLoader({ prop: "tripFreights", newValue: true })
        const response = await findTripById(tripId, filterFreightBills)
        const { status, data } = response.data
        if (status) {
          dispatchDispatch({ type: "UPDATE_TRIPS", payload: data })
          dispatchDispatch({ type: "SET_TRIP_SELECTED", payload: data })
          emitEvent("update-trip", { TYPE_ACTION: "CURRENT_SELECTED", ...data })
        }
      }
    } catch (error) {
    } finally {
      changeLoader({ prop: "tripFreights", newValue: false })
    }
  }

  const shareDirections = {
    directionsOnTheMap,
    setDirectionsOnTheMap,
    renderDirectionDispatchFreightsBillsToMap,
    showDirectionsInMap,
    setShowDirectionsInMap,
    waypoints,
    setWaypoints,
    creatingRoute,
    directionError,
  }

  const _statuses = {
    statusTrips,
  }

  const share: IDispatchContext = {
    ...shareDirections,
    ..._statuses,
    assignToggleCarrier,
    updateTripFreight,
    deselectedTrip,
    currentTripSelectedId,
    storeFilter,
    tableColumnSizes,
    tableSizes,
    filtersSectionFreightBills,
    setFiltersSectionFreightBills,
    columnsDispatches,
    setColumnsDispatches,
    filtersDispatches,
    setFiltersDispatches,
    fetchDispatches,
    debounceFetchDispatches,
    dispatches,
    handleChangePageDispatches,
    paginationDispatches,
    loadingDispatches,
    fetchLoadsStatusHistory,
    columnsTrucks,
    setColumnsTrucks,
    columnsTrailers,
    setColumnsTrailers,
    driverStates,
    trucksStates,
    trailersStates,
    dynamicSpace,
    setDynamicSpace,
    trips,
    loading,
    setLoading,
    createTrip,
    assign,
    unassign,
    setTrips,
    preventContextMenu,
    // @ts-ignore
    debounceFetchAllLoads,
    searchTrip,
    setSearchTrip,
    status,
    searchTerminalId,
    setSearchTerminalId,
    fetchEQClasses,
    EQClasses,
    setEQClasses,
    // 👉 Trips
    paginationTrips,
    filtersTrips,
    setFiltersTrips,
    fetchTrips,
    handleChangePageTrips,
    fetchTripsStatusHistory,
    prevFiltersTrips,
    setPrevFiltersTrips,
    // 👉 Loads
    searchLoad,
    setSearchLoad,
    fetchLoads,
    loads,
    paginationLoads,
    handleChangePageLoads,
    filtersLoads,
    setFiltersLoads,
    columnsLoads,
    setColumnsLoads,
    setColumnsLoadsPickups,
    columnsLoadsPickups,
    setColumnsLoadsDeliveries,
    columnsLoadsDeliveries,
    // 👉 Drivers
    fetchDrivers,
    drivers,
    paginationDrivers,
    handleChangePageDrivers,
    filtersDrivers,
    setFiltersDrivers,
    debounceFetchAllDrivers,
    fetchDriverStatusHistory,
    columnsDrivers,
    setColumnsDrivers,
    // 👉 Trucks
    fetchTrucks,
    trucks,
    paginationTrucks,
    handleChangePageTrucks,
    filtersTrucks,
    setFiltersTrucks,
    debounceFetchAllTrucks,
    fetchTruckStatusHistory,
    // 👉 Carriers
    filtersCarriers,
    setFiltersCarriers,
    fetchCarriers,
    setCarriers,
    carriers,
    loadingCarriers,
    columnsCarriers,
    setColumnsCarriers,
    // 👉 Trailers
    filtersTrailers,
    setFiltersTrailers,
    fetchTrailers,
    trailers,
    paginationTrailers,
    handleChangePageTrailers,
    debounceFetchAllTrailers,
    dragTripToLoadsToggle,
    handlerRequest,
    fetchTrailerStatusHistory,
    // 👉 Doors
    searchDoor,
    setSearchDoor,
    filtersDoors,
    setFiltersDoors,
    fetchDoors,
    setDoors,
    doors,
    paginationDoors,
    handleChangePageDoors,
    debounceFetchAllDoors,
    loadingDoors,
    prevFiltersDoors,
    setPrevFiltersDoors,
    // 👉 Terminals
    terminals,
    loadingTerminals,
    selectedTerminal,
    setSelectedTerminal,
    setTerminals,
    // 👉 Reorder columns
    columnsFreightBills,
    setColumnsFreightBills,
    columnsStatusHistoryTrips,
    setColumnsStatusHistoryTrips,
    columnsStatusHistoryDrivers,
    setColumnsStatusHistoryDrivers,
    columnsStatusHistoryTrucks,
    setColumnsStatusHistoryTrucks,
    columnsStatusHistoryTrailers,
    setColumnsStatusHistoryTrailers,
    columnsStatusHistoryFreights,
    setColumnsStatusHistoryFreights,
    columnsTrips,
    setColumnsTrips,
    columnsDoors,
    setColumnsDoors,
    loadingLoads,
    loadingDrivers,
    loadingTrucks,
    loadingTrailers,
    loadingTrips,
    filterFreightBills,
    setFilterFreightBills,
    findTripById,
    handleRenderFreightsClick,
    loadingFreightBills,
    buildPayload,
    assignToggleDriver,
    assignToggleTrailer,
    assignToggleTruck,
    modalAssign,
    setModalAssign,
    handleClickWithKeyControl,
    bulkAssignFreights,
    setBulkAssignFreights,
    showModalUpdateColumn,
    setShowModalUpdateColumn,
    handleUpdateColumnsClick,
    listColumnsToUpdate,
    setListColumnsToUpdate,
    handlerUpdateColumns,
    queuePromises,
    setQueuePromises,
    togglePromises,
    queueDelayInSeconds,
    setQueueDelayInSeconds,
    timeLeft,
    updateRouteSequence,
    loadingMiles,
    recalculateMiles,
    assignToggleDispatch,
    updateTripWithNewData,
    // 👉 Tab Trips
    setTabTripIndex,
    setTabActionIndex,
    setTabResourcesIndex,
    tabTripIndex,
    tabActionIndex,
    tabResourcesIndex,
    setFilterTripHistory,
    filterTripHistory,
    setFilterDriverHistory,
    filterDriverHistory,
    setFilterTrailerHistory,
    filterTrailerHistory,
    setFilterTruckHistory,
    filterTruckHistory,
    tabActionHistoryIndex,
    setTabActionHistoryIndex,
    mountedTabLoadRef,
    mountedTabTripRef,
    // 👉 Refactor
    assignUnassignFreightToTrip,
    loadFreights,
    setFreights,
    freights,
    loaders,
    changeLoader,
    addRemoveFreightToList,
    synchronized,
    dayFilters,
    setDayFilters,
    loadTrips,
    assignAndShowMessagePerOne,
    bulkAssignmentFreights,
    setBulkAssignmentFreights,
    canChangeOrder,
    setCanChangeOrder,
  }

  return <DispatchContext.Provider value={share}>{children}</DispatchContext.Provider>
}

export default DispatchContextProvider
