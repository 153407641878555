import { FaTimes, FaChevronDown } from "react-icons/fa"
import clsx from "clsx"
import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"

import { Input, InputProps } from "./Input"
import useClickOutside from "@/hooks/useClickOutside"
import Tailwindcss from "@/utils/tailwind"

interface Props<K> {
  className?: string
  children?: (item: K, index?: number) => React.ReactNode
  options: K[]
  field?: "search" | "select"
  renderLabel: (item: K) => string
  onChange: (item: K) => void
  reduce?: (item: K) => void
  value?: string
  position?: "to-top" | "to-bottom"
  initSearch?: string
  IconEnd?: React.ReactNode
  id?: string
  noClear?: boolean
  inputSize?: InputProps["inputSize"]
  fontSize?: InputProps["fontSize"]
  listItemSize?: string
}

interface OptionProps<K> {
  className?: string
  children: React.ReactNode
  data: K
}

interface TSelectSearchContext {
  open: boolean
  toggle: React.Dispatch<React.SetStateAction<boolean>>
  onSelected: (value: any) => void
}

const SelectSearchContext = createContext<TSelectSearchContext>({} as TSelectSearchContext)

export function SelectSearch<K>({
  children,
  options,
  field = "search",
  renderLabel,
  onChange,
  position = "to-top",
  initSearch = "",
  IconEnd,
  noClear = false,
  noPlaceholder = false,
  listItemSize,
  inputSize,
  fullWidth = false,
  ...props
}: Props<K> & { noPlaceholder?: boolean; fullWidth?: boolean }) {
  const { formatMessage: $t } = useIntl()
  const [open, toggle] = useState<boolean>(false)
  const [search, setSearch] = useState<string>(initSearch || "")
  const inputRef = useRef<HTMLDivElement | null>(null)

  let filteredOptions = options
  if (search && field === "search") {
    filteredOptions = options.filter((item) => renderLabel(item).toLocaleLowerCase().includes(search.toLocaleLowerCase()))
  }

  useClickOutside(inputRef, () => {
    if (open) {
      const normalizeSearch = String(search).trim().toLocaleUpperCase()
      if (normalizeSearch == String(initSearch).trim().toLocaleUpperCase()) {
        toggle(false)
      } else {
        resetState()
      }
    }
  })

  useEffect(() => {
    setSearch(String(initSearch ?? "").trim())
  }, [initSearch])

  const onSelected = (item: any) => {
    onChange(item)
    setSearch(renderLabel(item))
    toggle(false)
  }

  const resetState = () => {
    onChange({} as K)
    setSearch("")
    toggle(false)
  }

  const renderOption = (item: K, index: number) => children && children(item, index)

  const cssListItemSizeClasses = Tailwindcss.mergeClasses(
    "w-full flex flex-col border rounded overflow-y-auto scroll__styled absolute z-50 dark:border-slate-700 shadow",
    listItemSize ?? "max-h-[250px]",
  )

  return (
    <div className={clsx(fullWidth ? "flex-1 w-full" : "")}>
      <div className={clsx("-z-1 w-full h-full", open ? "relative" : "")} ref={inputRef}>
        <SelectSearchContext.Provider value={{ open, toggle, onSelected }}>
          <div className="flex h-full">
            <div className="group/item relative flex-1">
              <Input
                onFocus={() => toggle(true)}
                placeholder={!!noPlaceholder ? " " : field === "search" ? $t({ id: "Search" }) : `${$t({ id: "Select" })}..`}
                value={String(search).trim()}
                onChange={(ev) => setSearch(ev.target.value)}
                // readOnly={field === "select"}
                onBlur={(ev) => {
                  setTimeout(() => {
                    if (!String(ev.target.value).trim()) {
                      resetState()
                    }
                  }, 300)
                }}
                inputSize={inputSize}
                autoComplete="off"
                {...props}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-0.5 text-gray-400 gap-0">
                {!noClear && (
                  <FaTimes
                    className={clsx(
                      "invisible text-sm relative pointer-events-auto select-none text-red-600 cursor-pointer",
                      !!search.length && "group-hover/item:visible",
                    )}
                    onClick={resetState}
                  />
                )}
                <FaChevronDown />
              </div>
            </div>
            {IconEnd}
          </div>
          {open && !!filteredOptions.length && (
            <div className={clsx(cssListItemSizeClasses, position === "to-top" ? "bottom-0" : "top-full")}>
              {!!children
                ? filteredOptions.map((c, index) => renderOption(c, index))
                : filteredOptions.map((option, key) => (
                    <SelectSearch.Option key={key} data={option}>
                      {/* @ts-ignore */}
                      {option.name}
                    </SelectSearch.Option>
                  ))}
            </div>
          )}
        </SelectSearchContext.Provider>
      </div>
    </div>
  )
}

export function Option<K>({ data, children, className, ...props }: OptionProps<K>) {
  const { onSelected } = useContext(SelectSearchContext)
  return (
    <button
      type="button"
      className={clsx(
        "flex-1 w-full bg-white hover:bg-gray-100 p-1 text-xs text-start pointer-events-auto dark:bg-slate-800 dark:text-slate-300 dark:hover:bg-slate-700",
        className,
      )}
      onClick={() => onSelected(data)}
      {...props}
    >
      {children}
    </button>
  )
}

SelectSearch.Option = Option
