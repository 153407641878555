import type { Load, TripFreightsStatuses } from "@pages/Loads/LoadType"
import type {
  ExtraData,
  ICarrierToTable,
  IDispatchToTable,
  IDriverToTable,
  IFreightBillToTable,
  IFreightStatusToTable,
  ILoadToTable,
  IStatusHistoryDriver,
  IStatusHistoryTrailer,
  IStatusHistoryTruck,
  ITabDoorsToTable,
  ITabLTLPlanToTable,
  ITabTripToTable,
  ITrailerToTable,
  ITripStatus,
  ITripToTable,
  ITruckToTable,
  LocationData,
} from "@pages/DispatchPage/DispatchType"
import type { Freight, Trip, TripStatus } from "../Trips/TripType"
import type { Driver, DriverHistory } from "../Drivers/DriverType"
import type { StatusHistory as StatusHistoryTruck, Truck } from "../Trucks/TruckType"
import type { StatusHistory as StatusHistoryTrailer, Trailer } from "../Trailers/TrailerType"
import type { Door } from "../Doors/DoorType"
import type { Carrier } from "../Carriers/CarrierType"

import Format, { formatDate, formatPhoneNumber, formatTime, timestampToDate, tryParseJSONObject } from "@/utils/formats"

const DEFAULT_VALUE = "-"
const DEFAULT_VALUE_NUMBER = 0

class DispatchAdapter {
  static LoadsToTable(load: Load): ILoadToTable {
    const pallets = load.freight_details.reduce<number>((acc, item) => acc + Number(isNaN(Number(item.pallets)) ? "0" : item.pallets), 0)
    const weight = load.freight_details.reduce<number>((acc, item) => acc + Number(isNaN(Number(item.weight)) ? "0" : item.weight), 0)
    const newCharges = Number(load.charges ?? "0")
    return {
      ...load,
      id: load.id,
      bill_number: load.bill_number,
      statusCode: load.status_code,
      originName: load.origin_name,
      destinationName: load.destination_name,
      date_pickup_start: Format.dateToStringShort(load.date_pickup_start || ""),
      date_pickup_end: Format.dateToStringShort(load.date_pickup_end || ""),
      date_delivery_start: Format.dateToStringShort(load.date_delivery_start || ""),
      date_delivery_end: Format.dateToStringShort(load.date_delivery_end || ""),
      locationOrigin: `${load.origin_city ?? ""} - ${load.origin_state ?? ""} ${load.origin_zip_code ?? ""}`,
      locationDestination: `${load.destination_city ?? ""} - ${load.destination_state ?? ""} ${load.destination_zip_code ?? ""}`,
      serviceLevel: load.service_level?.name ?? "-",
      pickup_lat: Number(load.pickup_latitude) || Number(load.origin?.latitude),
      pickup_lng: Number(load.pickup_longitude) || Number(load.origin?.longitude),
      delivery_lat: Number(load.delivery_latitude) || Number(load.destination?.latitude),
      delivery_lng: Number(load.delivery_longitude) || Number(load.destination?.longitude),
      origin: load.origin,
      destination: load.destination,
      trip_info: load.trip_info,
      trip_number: load.trip_number,
      pickupSchedule: `${formatDate(load.date_pickup_start!)} / ${formatTime(load.date_pickup_start!)} - ${formatTime(load.date_pickup_end!)}`,
      deliverySchedule: `${formatDate(load.date_delivery_start!)} / ${formatTime(load.date_delivery_start!)} - ${formatTime(load.date_delivery_end!)}`,
      charges: isNaN(newCharges) ? 0 : newCharges,
      pallets,
      weight,
      createdByName: load.created_by?.name ?? "-",
      createdByEmail: load.created_by?.email ?? "-",
      billName: load.bill_to?.name ?? "-",
      billEmail: load.bill_to?.email ?? "-",
      customerName: load.customer?.name,
      customerEmail: load.customer?.email,
      customerPhone: formatPhoneNumber(load.customer?.phone_number ?? ""),
      margin: load.margin ?? "-",
      tx_type: load.tx_type,
    }
  }
  static TripToTable(trip: Trip): ITripToTable {
    const [driver] = trip.drivers
    const [truck] = trip.trucks
    const [trailer] = trip.trailers
    return {
      id: trip.id,
      status: trip.status?.status_code || DEFAULT_VALUE,
      driver: [driver?.name, driver?.phone_number].filter(Boolean).join(" ") || DEFAULT_VALUE,
      truck: truck?.name ?? "-",
      trailer: trailer?.name ?? "-",
      assigned: trip.freights.length,
    }
  }
  static DriversToTable(driver: Driver): IDriverToTable {
    return {
      ...driver,
      city: driver.city || DEFAULT_VALUE,
      state: driver.state || DEFAULT_VALUE,
      zip_code: driver.zip_code || DEFAULT_VALUE,
      statusCode: driver.status?.status_code ?? DEFAULT_VALUE,
      phoneNumber: Format.formatPhoneNumber(driver.phone_number || "") || DEFAULT_VALUE,
      creatorName: driver.user_created?.name ?? DEFAULT_VALUE,
      creatorEmail: driver.user_created?.email ?? DEFAULT_VALUE,
      terminalName: driver.terminal?.name ?? DEFAULT_VALUE,
      terminalAddress: driver.terminal?.address_1 ?? DEFAULT_VALUE,
      lastTripId: Number(!!driver.last_trip ? (typeof driver.last_trip === "number" ? driver.last_trip : driver.last_trip?.id) : DEFAULT_VALUE_NUMBER),
      currentTripId: Number(
        !!driver.current_trip ? (typeof driver.current_trip === "number" ? driver.current_trip : driver.current_trip?.id) : DEFAULT_VALUE_NUMBER,
      ),
      nextTripId: Number(!!driver.next_trip ? (typeof driver.next_trip === "number" ? driver.next_trip : driver.next_trip?.id) : DEFAULT_VALUE_NUMBER),
    }
  }
  static TruckToTable(truck: Truck): ITruckToTable {
    return {
      ...truck,
      plate: truck.plate || DEFAULT_VALUE,
      model: truck.model || DEFAULT_VALUE,
      make: truck.make || DEFAULT_VALUE,
      year: truck.year || DEFAULT_VALUE,
      statusCode: truck.status?.status_code ?? DEFAULT_VALUE,
      terminalName: truck.terminal?.name ?? DEFAULT_VALUE,
      terminalAddress: truck.terminal?.address_1 ?? DEFAULT_VALUE,
    }
  }
  static TrailerToTable(trailer: Trailer): ITrailerToTable {
    return {
      ...trailer,
      model: trailer.model ?? DEFAULT_VALUE,
      plate: trailer.plate ?? DEFAULT_VALUE,
      year: trailer.year ?? DEFAULT_VALUE,
      statusCode: trailer.status?.status_code ?? DEFAULT_VALUE,
      terminalName: trailer.terminal?.name ?? DEFAULT_VALUE,
      terminalAddress: trailer.terminal?.address_1 ?? DEFAULT_VALUE,
    }
  }
  static DispatchToTable(user: User): IDispatchToTable {
    return {
      ...user,
      roleName: user.role?.description ?? DEFAULT_VALUE,
    }
  }
  private static getTripProperties(freight: Freight) {
    const detail = freight.freight_details[0] ?? {}
    return {
      description: detail.description || undefined,
      weight: Number(detail.weight ?? "0"),
      pieces: Number(detail.pieces ?? "0"),
      pallets: Number(detail.pallets ?? "0"),
      _length: Number(detail._length ?? "0"),
      width: Number(detail.width ?? "0"),
      height: Number(detail.height ?? "0"),
    }
  }
  private static getDataLocation(freight: Freight): LocationData {
    if (freight.tx_type === "P") {
      return {
        locName: freight.origin_name,
        locAddress: freight.origin_address_1,
        locCity: freight.origin_city,
        locZip: freight.origin_zip_code,
        locState: freight.origin_state,
        locLatitude: Number(freight.pickup_latitude),
        locLongitude: Number(freight.pickup_longitude),
      }
    } else {
      return {
        locName: freight.destination_name,
        locAddress: freight.destination_address_1,
        locCity: freight.destination_city,
        locZip: freight.destination_zip_code,
        locState: freight.destination_state,
        locLatitude: Number(freight.delivery_latitude),
        locLongitude: Number(freight.delivery_longitude),
      }
    }
  }
  static FreightBillsToTable(freight: Freight): IFreightBillToTable {
    const detail = DispatchAdapter.getTripProperties(freight)
    return {
      ...freight,
      ...detail,
      tx_type_name: freight.tx_type === "D" ? "Delivery" : "Pickup",
      date_pickup_start_to_text: Format.dateToStringShort(freight.date_pickup_start!),
      date_delivery_start_to_text: Format.dateToStringShort(freight.date_delivery_start!),
      creatorName: freight.created_by?.name ?? "-",
      creatorEmail: freight.created_by?.email ?? "-",
      terminalName: freight.terminal?.name ?? "-",
      terminalAddress: freight.terminal?.address_1 ?? "-",
      miles: Number(freight.miles ?? "0"),
      ...DispatchAdapter.getDataLocation(freight),
    }
  }
  static FreightStatusToTable(data: TripFreightsStatuses): IFreightStatusToTable {
    return {
      ...data,
      creatorName: data.updated_by?.name ?? "-",
      creatorEmail: data.updated_by?.email ?? "-",
    }
  }
  static TripStatusToTable(tripStatus: TripStatus): ITripStatus {
    const newValue = {
      ...tripStatus,
      date_change: Format.timestampToDate(tripStatus.date_change),
      timeChanged: Format.timestampToDate(tripStatus.date_change),
      insDate: Format.timestampToDate(tripStatus.date_change),
      zone: "-",
      user: tripStatus?.updated_by?.name || "-",
      defaultEmpty: "-",
    }
    const extraData = tryParseJSONObject(tripStatus.extra_data ?? "") as ExtraData
    if (extraData && tripStatus.status_code === "POSITION") {
      newValue.zone = extraData.GPS_ZONE
      newValue.comments = extraData.ADDRESS
      newValue.user = extraData.DRIVER_ID
    }
    return newValue
  }
  static DriverHistoryToTable(item: DriverHistory): IStatusHistoryDriver {
    return {
      ...item,
      code: item.driver_obj?.id ?? "",
      driverName: item.driver_obj?.name ?? "",
      driverPhone: Format.formatPhoneNumber(item.driver_obj?.phone_number ?? ""),
      created_at: Format.timestampToDate(item.created_at),
      userName: item.updated_id?.name ?? "",
      defaultEmpty: "-",
    }
  }
  static TruckHistoryToTable(item: StatusHistoryTruck): IStatusHistoryTruck {
    return {
      ...item,
      code: item.truck_obj?.id ?? "",
      truckName: item.truck_obj?.name ?? "",
      created_at: Format.timestampToDate(item.created_at),
      userName: item.updated_id.name,
      defaultEmpty: "-",
    }
  }
  static TrailerHistoryToTable(item: StatusHistoryTrailer): IStatusHistoryTrailer {
    return {
      ...item,
      code: item.trailer_obj?.id,
      trailerName: item.trailer_obj?.name,
      created_at: Format.timestampToDate(item.created_at),
      userName: item.updated_id.name,
      defaultEmpty: "-",
    }
  }
  static TabTripsToTable(item: Trip): ITabTripToTable {
    const driverNames = item.drivers.map((c) => `${c.name} - ${Format.formatPhoneNumber(c.phone_number ?? "")}`).join(", ") || DEFAULT_VALUE
    const truckNames = item.trucks.map((c) => c.name).join(", ") || DEFAULT_VALUE
    const trailerNames = item.trailers.map((c) => c.name).join(", ") || DEFAULT_VALUE
    return {
      ...item,
      driverNames,
      truckNames,
      trailerNames,
      a_length: Number(item.a_length) ?? DEFAULT_VALUE,
      pieces: Number(item.pieces) ?? DEFAULT_VALUE,
      pallets: Number(item.pallets) ?? DEFAULT_VALUE,
      cubes: item.cubes ?? DEFAULT_VALUE,
      freightLength: item.freights?.length || DEFAULT_VALUE,
      statusCode: item.status?.status_code || DEFAULT_VALUE,
      currentZone: item.current_zone?.zone_code ?? DEFAULT_VALUE,
      startZone: item.origin_zone?.zone_code ?? DEFAULT_VALUE,
      endZone: item.destination_zone?.zone_code ?? DEFAULT_VALUE,
      defaultEmpty: DEFAULT_VALUE,
      createdByName: item.created_by?.name || DEFAULT_VALUE,
      createdByEmail: item.created_by?.email || DEFAULT_VALUE,
      terminalAddress: item.terminal?.address_1 ?? DEFAULT_VALUE,
      terminalName: item.terminal?.name ?? DEFAULT_VALUE,
      creationDate: timestampToDate(item.created_at) || DEFAULT_VALUE,
      dispatchName: item.dispatch?.name ?? DEFAULT_VALUE,
      dispatchEmail: item.dispatch?.email ?? DEFAULT_VALUE,
    }
  }
  static TabLTLPlanToTable(item: Trip): ITabLTLPlanToTable {
    return {
      ...item,
      defaultEmpty: "-",
    }
  }
  static TabDoorToTable(item: Door): ITabDoorsToTable {
    return {
      ...item,
      terminalName: item.terminal?.name,
      terminalAddress: item.terminal?.address_1,
      creationDate: timestampToDate(item.created_at),
      doorDescription: item.door_desc ?? DEFAULT_VALUE,
      defaultEmpty: DEFAULT_VALUE,
      driverNames: DEFAULT_VALUE,
      truckNames: DEFAULT_VALUE,
      trailerNames: DEFAULT_VALUE,
      statusName: DEFAULT_VALUE,
    }
  }
  static CarrierToTable(item: Carrier): ICarrierToTable {
    return {
      ...item,
      status_name: item.active ? "ACTIVE" : "INACTIVE",
      created_by_name: item.user_created.name ?? DEFAULT_VALUE,
      created_by_email: item.user_created.email ?? DEFAULT_VALUE,
    }
  }
}

export default DispatchAdapter
