import { SocketContext } from "@/context/SocketContext"
import { useContext, useEffect, useRef } from "react"

interface LoadDataFromSocketParam {
  (...args: any): void
}

const useLoadDataFromSocket = (callback: LoadDataFromSocketParam) => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const { isConnected, socket } = useContext(SocketContext)

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(callback, 200)
  }, [callback, socket, isConnected])

  return
}

export default useLoadDataFromSocket
