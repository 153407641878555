import Axios, { AxiosResponse, InternalAxiosRequestConfig, AxiosError } from "axios"
import Cookies from "js-cookie"
import { KEY_TOKEN, LOCALE } from "@/constants"

const instance = Axios.create({ baseURL: import.meta.env.VITE_API_URL })

// 👉 Response
const errorRequestHandler = (error: AxiosError) => Promise.reject(error)
const successRequestHandler = (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(KEY_TOKEN)
  // if (token) config.headers.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNDEzNDRhMDE2NmM2NTQ5OTBiNDM2YTFjYjA5YWViZGU4YWY1NjEwNmI1YThiM2E2NDQ1OTE3YzRmOTZkZDNkZmM3NmQ0NGI0NWU2ODc0ZGYiLCJpYXQiOjE3MjQ4ODI0ODMuMjYzNzAyLCJuYmYiOjE3MjQ4ODI0ODMuMjYzNzA3LCJleHAiOjE3NTY0MTg0ODMuMjYwNzM0LCJzdWIiOiIxMSIsInNjb3BlcyI6W119.iZzXtGN1I7zBVll71_fxhxzJT2ztgZZIDQNP4qYsj6Mh0FayQ-jjYrnSBDwn2S-A82RFbi6ROhAI0-6OFCHfg-GoEIW97leiJo73vBWNxtcO9yIEdgN6--HDdPjYLbDvUdADiJpA4GFpuRmR-YzBCbiIISTBqxuQcGpeI0oceyKKWkE79OM_-XPpNnT-AJIxKm9QG_gngsaHNiMGYtZI_1yZx4dOaSdy5Mz0FHGyrCzsGDSWJx0N19xuls-Z0L-3guLSamMpCyPbCNumm_jN6ZlyQv_j7jBO-UsGMzVW1OEDxWHcB-y-oosdaQqecL3bJfXHPDbeeiNnE9nf_quIkMP1mSEO1fPUNZIkgGdx-cUHD8-bOHfc2GWl2hpXSSnji42kc3xyltEh6WIc6FbnnCuHQ75AyPYAahVMOFV76xTZVfc0wjphp8xYlxkJ2MphjHwe6cHT6fN85n5z23tEPdz98jF2Xcs1Nrr7jfaq3HDIFCd0QI-1cuKPBDv2TMgdNeCKgWUIHr5YHISx31-aXEbHpYtAQ8F88HwgBUyujmxA0CKzZLe6SCaXPrUoZKao9jH6WsiI6MxGM6z0fQxGHM8h7S8uEwYenTXoZPGaT1i9rhNvdQurAwHJXsK2_eQcqLEsZ9M6D-p7At-zcebuR5foJLzWFeYerzDP4kcs8eA`
  if (token) config.headers.Authorization = `Bearer ${token}`
  config.headers["Accept-Language"] = String(Cookies.get(LOCALE) ?? "EN").toLowerCase()
  return config
}
instance.interceptors.request.use(successRequestHandler, errorRequestHandler)

// 👉 Response
const errorResponseHandler = (error: AxiosError) => Promise.reject(error)
const successResponseHandler = (response: AxiosResponse) => response
instance.interceptors.response.use(successResponseHandler, errorResponseHandler)

export default instance
