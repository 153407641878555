import { useState, useEffect } from "react"

interface TitleUpdate {
  (newTitle: string): void
}

const useDocumentTitle = (initialTitle: string, prefix: string = "T4 TMS"): TitleUpdate => {
  const [title, setTitle] = useState(buildTitle(initialTitle))

  function buildTitle(str: string): string {
    let _newTitle = str
    if (prefix) {
      _newTitle += ` | ${prefix}`
    }
    return _newTitle
  }

  const updateTitle: TitleUpdate = (newTitle) => {
    const _newTitle = buildTitle(newTitle)
    setTitle(_newTitle)
    document.title = _newTitle
  }

  useEffect(() => {
    document.title = title
  }, [title])

  return updateTitle
}

export default useDocumentTitle
