import { FaPaperPlane, FaPaperclip, FaXmark, FaMinus, FaRegCircleXmark, FaRegHandPointDown } from "react-icons/fa6"
import { ChangeEvent, DragEvent, MouseEvent, useCallback, useContext, useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { useIntl } from "react-intl"
import axios from "axios"

import ChatBubble from "@/@core/ChatBubble"
import ChatBubbleContainer from "@/@core/ChatBubbleContainer"
import { SocketContext } from "@/context/SocketContext"
import { DriverWithMessage, Message } from "@/context/types"

import IconImage from "@/assets/images/icon-image.png"
import IconPdf from "@/assets/images/icon-pdf.png"

type Props = {
  driver: DriverWithMessage
  username: string
}

type ResponseTyping = {
  newState: boolean
  session_id: number
}

const ALLOWED_EXTENSIONS = ["jpg", "jpeg", "png", "gif", "pdf"]

const ChatContact = ({ driver, username }: Props): JSX.Element => {
  const { formatMessage: $t } = useIntl()
  const { onlineUsers, unselectContact, socket, joinedRoom, setJoinedRoom, getHistoryMessages } = useContext(SocketContext)
  const [message, setMessage] = useState<string>("")
  const [typing, setTyping] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const inputFileRef = useRef<HTMLInputElement | null>(null)
  const [messageId, setMessageId] = useState<number | undefined>()
  const [file, setFile] = useState<IFile | undefined>(undefined)
  const [isDrag, setIsDrag] = useState<boolean>(false)

  const hasValidExtension = () => {
    if (!inputFileRef.current) return false
    if (!file) return
    const extension = file.name.split(".").pop()!.toLowerCase()
    if (ALLOWED_EXTENSIONS.indexOf(extension) == -1) {
      inputFileRef.current.value = ""
      const translation = $t(
        { id: "Your file is not a valid file. Allowed files are ext" },
        {
          nameFile: file.name,
          extensions: ALLOWED_EXTENSIONS.join(", "),
        },
      )
      return alert(translation)
    }
    return true
  }

  useEffect(() => {
    scrollToBottom()
  }, [typing, driver.messages?.length])

  useEffect(() => {
    if (joinedRoom) {
      if (joinedRoom.user2_id === driver.name) {
        setTimeout(() => inputRef.current && inputRef.current.focus(), 500)
        if (!driver.messages?.length) {
          getHistoryMessages({ sessionId: driver.roomId! })
        }
      }
    }
  }, [joinedRoom])

  const scrollToBottom = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          left: 100,
          behavior: "smooth",
        })
      }
    }, 300)
  }

  const onTyping = (response: ResponseTyping) => {
    if (response.session_id == driver.roomId) {
      setTyping(response.newState)
    }
  }

  const onChangeMessage = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(ev.target.value)
    socket?.emit("typing", { newState: !!ev.target.value.length, session_id: driver.roomId, toUser: driver.name })
  }

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key !== "Enter" || !message.trim().length) return
    onSubmit()
  }

  const onClose = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    unselectContact(driver)
    if (driver.room?.id === joinedRoom?.id) {
      socket?.emit("salir", driver.room?.id)
      setJoinedRoom(undefined)
    }
  }

  useEffect(() => {
    socket?.on("typing", onTyping)
    return () => {
      socket?.off("typing", onTyping)
    }
  }, [joinedRoom, socket])

  const onSubmit = useCallback(async () => {
    if (sending) return
    try {
      if (!file) {
        const normalizeMessage = message.replace(/</g, "&lt;").replace(/>/g, "&gt;")
        const payload = {
          message: normalizeMessage,
          session_id: driver.roomId,
          content: "text",
          user: username,
          driver: driver.name,
        }
        if (!messageId) {
          socket?.emit("chat", payload)
          setMessage("")
        } else {
          socket?.emit("update-message", {
            newText: message,
            messageId,
          })
          clearForm()
        }
      } else {
        setSending(true)
        const isValid = hasValidExtension()
        if (!isValid) return
        const formData = new FormData()
        formData.append("content", file.type !== "application/pdf" ? "image" : "pdf")
        formData.append("session_id", String(driver.room?.id))
        formData.append("where", "WEB")
        formData.append("user", username)
        formData.append("driver", driver.name)
        formData.append("namespace", socket.nsp)
        // @ts-ignore
        formData.append("image", file)
        await axios.post(`${import.meta.env.VITE_SOCKET_URI}/upload-file`, formData)
        clearFormFile()
      }
    } catch (error) {
      console.error("onError -> error", error)
    } finally {
      setSending(false)
    }
  }, [socket, file, sending, message])

  const calculateLastIndex = (): number => {
    let _lastIndex: number = -1
    const newMessages = driver.messages ?? []
    for (let i = newMessages.length - 1; i >= 0; i--) {
      if (newMessages[i].type == 0) {
        _lastIndex = i
        break
      }
    }
    return _lastIndex
  }

  const indexMyLastMessage: number = calculateLastIndex()

  const handlerEdit = (message: Message) => {
    setMessageId(message.id)
    setMessage(message.message)
  }

  const handlerDelete = (message: Message) => {
    if (!joinedRoom) return
    socket?.emit("destroy-message", {
      messageId: message.id,
      sessionId: driver.room?.id,
    })
  }

  const clearForm = () => {
    setMessageId(undefined)
    setMessage("")
  }

  const handleJoinRoomClick = (ev: MouseEvent<HTMLInputElement | HTMLDivElement>) => {
    ev.stopPropagation()
    if (!!joinedRoom && joinedRoom.id !== driver.roomId) {
      setJoinedRoom(driver.room)
    } else if (!joinedRoom) {
      setJoinedRoom(driver.room)
    }
  }

  const messages = driver.messages || []

  const handleInputFileClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click()
    }
  }

  const onChangeInputFile = (ev: ChangeEvent<HTMLInputElement>) => {
    const count = ev.target.files?.length
    if (count) {
      const [newFile] = ev.target.files!
      setFile(newFile)
      setMessage(newFile.name)
    } else {
      clearFormFile()
    }
  }

  function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
  }

  const clearFormFile = () => {
    setFile(undefined)
    setMessage("")
    if (inputFileRef.current) {
      inputFileRef.current.value = ""
    }
  }

  const onDragOver = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (!!messageId) return
    setIsDrag(true)
  }

  const onDragLeave = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (!!messageId) return
    setIsDrag(false)
  }

  const onDrop = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (!!messageId) return
    setIsDrag(false)
    const [firstFile] = ev.dataTransfer.files
    if (firstFile) {
      setFile(firstFile)
      setMessage(firstFile.name)
    }
  }

  return (
    <>
      <div
        className={
          "w-full sm:w-[300px] bg-white dark:bg-slate-700 dark:text-slate-300 border border-b-0 dark:border-slate-700 flex flex-col pointer-events-auto relative"
        }
        onClick={handleJoinRoomClick}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          className={clsx(
            "absolute transition-opacity left-0 bottom-0 h-full w-full bg-white dark:bg-slate-700 z-10 pointer-events-none grid place-content-center shadow-inner outline-dashed -outline-offset-4 opacity-0",
            isDrag ? "opacity-100" : "pointer-events-none",
          )}
        >
          <FaRegHandPointDown className="pointer-events-none text-[30px] mx-auto" />
          <p className="mt-1 pointer-events-none text-sm first-letter:capitalize">{$t({ id: "drop here" })}</p>
        </div>
        <div
          className={clsx(
            "flex items-center justify-between py-2 px-2 border-b dark:border-slate-700",
            joinedRoom?.id === driver.roomId ? "bg-darkblue" : "bg-[#405188]",
          )}
        >
          <div className="leading-[10px] py-0.5 text-white">
            <h4 className="text-xs font-semibold capitalize">
              <span
                className={clsx("inline-block w-2 h-2 rounded-full", onlineUsers.some((c) => c.username === driver.name) ? "bg-green-500" : "bg-gray-400")}
              ></span>{" "}
              {String(driver.name).toLocaleLowerCase()}
            </h4>
          </div>
          <div className="flex items-center gap-1">
            <button className="hidden text-gray-300 hover:text-white">
              <FaMinus className="text-[16px]" onClick={() => {}} />
            </button>
            <button onClick={onClose} className="text-gray-300 hover:text-white">
              <FaXmark className="text-[16px]" />
            </button>
          </div>
        </div>
        <ChatBubbleContainer ref={containerRef}>
          {messages.map((message, index) => (
            <ChatBubble
              key={index}
              username={driver.name}
              message={message}
              onEdit={handlerEdit}
              onDelete={handlerDelete}
              showControls={indexMyLastMessage === index}
            />
          ))}
          {typing && (
            <div>
              <span className="text-xs inline-block typed capitalize">{$t({ id: "typing" })}...</span>
            </div>
          )}
        </ChatBubbleContainer>
        <div className="border dark:border-slate-700 dark:bg-slate-900 flex items-center w-full">
          <button className="ps-2 pe-1 text-darkblue dark:text-slate-300 disabled:opacity-40" onClick={handleInputFileClick} disabled={!!messageId}>
            <FaPaperclip />
          </button>
          <input ref={inputFileRef} onChange={onChangeInputFile} type="file" accept=".jpg,.jpeg,.png,.gif,.pdf,.pdf" className="hidden" />
          {file ? (
            <div className="w-full h-[42px] flex justify-between">
              <div className="flex gap-2 items-center">
                {file.type === "application/pdf" ? (
                  <img src={IconPdf} className="w-6" alt="Icon pdf" />
                ) : (
                  <img src={IconImage} className="w-6" alt="Icon image" />
                )}
                <div className="flex flex-col text-[10px] leading-none">
                  <span>{file.name}</span>
                  <span>{file.type}</span>
                  <span>{formatBytes(file.size)}</span>
                </div>
              </div>
              <button className="transition-transform px-1.5 text-red-500" onClick={clearFormFile}>
                <FaRegCircleXmark className="text-red-500" />
              </button>
            </div>
          ) : (
            <input
              type="text"
              ref={inputRef}
              placeholder={`${$t({ id: "Write a message" })}...`}
              className="px-1 py-3 text-sm outline-none w-full placeholder:text-gray-400 focus:placeholder:translate-x-1 placeholder:transition-all focus:placeholder:opacity-50 pe-0.5 dark:text-slate-400 dark:bg-slate-900"
              value={message}
              onChange={onChangeMessage}
              onKeyDown={handleKeyDown}
              onClick={handleJoinRoomClick}
            />
          )}
          <div className="flex">
            {!!messageId && (
              <button className="transition-transform px-1.5 text-red-500" onClick={clearForm}>
                <FaRegCircleXmark className="text-red-500" />
              </button>
            )}
            <button
              className={"transition-transform px-1.5 text-darkblue disabled:opacity-40 dark:text-slate-300"}
              onClick={onSubmit}
              disabled={!message.length || sending}
            >
              <FaPaperPlane />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatContact
