import { createContext, useContext, useEffect, useMemo, useReducer } from "react"

import type { IAppContext } from "./types"
import type { Load } from "@/pages/Loads/LoadType"
import { SocketContext } from "@/context/SocketContext"
import { dispatchMapReducer, loadReducer, dispatchReducer, appReducer } from "@/store/reducers"
import InitialState from "@/store/appInitial.state"
import { Adapter } from "@/pages/Loads/LoadAdapter"
import { getUserFromLocalStorage } from "./AuthenticateContext"

export const AppContext = createContext({} as IAppContext)
const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [storeDispatchMap, dispatchDispatchMap] = useReducer(dispatchMapReducer, InitialState.dispatchMap)
  const [storeLoads, dispatchLoads] = useReducer(loadReducer, InitialState.load)
  const [storeDispatch, dispatchDispatch] = useReducer(dispatchReducer, InitialState.dispatch)
  const [storeApp, dispatchApp] = useReducer(appReducer, InitialState.app)
  const { isConnected, socket } = useContext(SocketContext)

  const createFreightHandler = (payload: Load) => {
    dispatchLoads({ type: "ADD_FREIGHT_TO_LIST", payload })
    dispatchDispatch({
      type: "TOGGLE_ADD_TO_FREIGHTS",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "D"),
        type: "Delivery",
        action: "ADD",
      },
    })
    dispatchDispatch({
      type: "TOGGLE_ADD_TO_FREIGHTS",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "P"),
        type: "Pickup",
        action: "ADD",
      },
    })
  }
  const updateFreightHandler = (payload: unknown) => {
    dispatchLoads({ type: "UPDATE_FREIGHT_TO_LIST", payload })
  }
  const destroyFreightHandler = (payload: Load) => {
    dispatchLoads({ type: "REMOVE_FREIGHT_TO_LIST", payload })
    dispatchDispatch({
      type: "TOGGLE_ADD_TO_FREIGHTS",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "D"),
        freightId: payload.id,
        type: "Delivery",
        action: "REMOVE",
      },
    })
    dispatchDispatch({
      type: "TOGGLE_ADD_TO_FREIGHTS",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "P"),
        freightId: payload.id,
        type: "Pickup",
        action: "REMOVE",
      },
    })
  }
  const addDocumentFreightHandler = (payload: unknown) => {
    dispatchLoads({ type: "ADD_DOCUMENT_TO_FREIGHT", payload })
  }
  const toggleAssignToTripHandler = ({ TYPE_ACTION, ...payload }: any) => {
    if (TYPE_ACTION === "FREIGHTS") {
      dispatchLoads({ type: "TOGGLE_ADD_TRIP_INFO_TO_FREIGHT", payload })
    } else if (TYPE_ACTION === "TOGGLE_ADD_TO_FREIGHTS") {
      dispatchDispatch({ type: "TOGGLE_ADD_TO_FREIGHTS", payload })
    }
  }
  const updateTripHandler = ({ TYPE_ACTION, ...payload }: any) => {
    switch (TYPE_ACTION) {
      case "CURRENT_SELECTED":
        dispatchDispatch({ type: "SET_TRIP_SELECTED", payload })
        break
      case "LIST":
        dispatchDispatch({ type: "UPDATE_TRIPS", payload })
        break
      default:
        break
    }
  }
  const createTripHandler = (payload: unknown) => {
    dispatchDispatch({ type: "TOGGLE_ADD_TRIP_TO_TRIPS", payload })
  }
  const getPageHistory = (payload: any) => {
    dispatchApp({ type: "SET_VISITED_PAGES", payload: payload?.visitedPages || [] })
  }
  const getMenuIconsHandler = (menuIcons: any) => {
    dispatchApp({ type: "LOAD_MENU_ICONS", payload: menuIcons })
  }

  useEffect(() => {
    if (isConnected && socket) {
      socket.on("create-freight", createFreightHandler)
      socket.on("update-freight", updateFreightHandler)
      socket.on("destroy-freight", destroyFreightHandler)
      socket.on("add-document-freight", addDocumentFreightHandler)
      socket.on("assign-or-unassign-to-trip", toggleAssignToTripHandler)
      socket.on("create-trip", createTripHandler)
      socket.on("update-trip", updateTripHandler)
      socket.on("get-visited-pages", getPageHistory)
      socket.on("get-menu-icons", getMenuIconsHandler)
      const user: User = getUserFromLocalStorage()
      socket.emit("get-visited-pages", { companyId: user.company_id, userId: user.id })
      socket.emit("get-menu-icons")
      // socket.on("destroy-trip", (payload: unknown) => {})
      // socket.on("create-door", (payload: unknown) => {})
      // socket.on("update-door", (payload: unknown) => {})
      // socket.on("destroy-door", (payload: unknown) => {})
    }
    return () => {
      socket?.off("create-freight", createFreightHandler)
      socket?.off("update-freight", updateFreightHandler)
      socket?.off("destroy-freight", destroyFreightHandler)
      socket?.off("add-document-freight", addDocumentFreightHandler)
      socket?.off("assign-or-unassign-to-trip", toggleAssignToTripHandler)
      socket?.off("update-trip", updateTripHandler)
      socket?.off("get-visited-pages", getPageHistory)
      socket?.off("get-menu-icons", getMenuIconsHandler)
      // socket?.off("set-visited-pages", setPageHistory)
    }
  }, [socket, isConnected])

  const iconIndexed = useMemo(() => new Map(storeApp.menuIcons.map((c) => [c.iconId, c.icon])), [storeApp.menuIcons])

  useEffect(() => {
    if (isConnected) {
      const user: User = getUserFromLocalStorage()
      socket.emit("set-visited-pages", {
        companyId: user.company_id,
        userId: user.id,
        newPages: storeApp.visitedPages,
      })
    }
  }, [socket, isConnected, storeApp])

  const share: IAppContext = {
    storeDispatchMap,
    dispatchDispatchMap,
    storeLoads,
    dispatchLoads,
    storeDispatch,
    dispatchDispatch,
    storeApp,
    dispatchApp,
    iconIndexed,
  }

  return <AppContext.Provider value={share}>{children}</AppContext.Provider>
}

export default AppContextProvider
