import clsx from "clsx"
import React from "react"
import { createPortal } from "react-dom"
import { FaTimes } from "react-icons/fa"
import { Tooltip } from "react-tooltip"

interface Props {
  open?: boolean
  onClose?: () => void
  title?: string | React.ReactNode
  children?: React.ReactNode
  footer?: React.ReactNode
  size?: "xxs" | "sm" | "lg" | "xl" | "xxl" | "xxxl" | "full" | "default"
  customSize?: string
  classesWrapper?: string
}

export const Modal = ({ open = true, onClose, title, children, size = "default", customSize, classesWrapper }: Props): JSX.Element | null => {
  if (!open) return null

  const _size = {
    xxs: "sm:max-w-[350px]",
    sm: "sm:max-w-[500px]",
    lg: "sm:max-w-[700px]",
    xl: "sm:max-w-[900px]",
    xxl: "sm:max-w-[1100px]",
    xxxl: "sm:max-w-[96vw]",
    full: "sm:max-w-full min-h-screen h-screen !rounded-none",
    default: "sm:max-w-[600px]",
  }[size]

  return createPortal(
    <div
      className="fixed left-0 top-0 overflow-hidden w-screen h-screen bg-gray-500 dark:bg-slate-950/90 bg-opacity-50 transition-opacity z-[100] flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className={clsx("flex items-center justify-center w-full sm:block text-center", size == "full" ? "p-0" : "px-2")}>
        <div className={clsx("bg-white dark:bg-slate-900 rounded-md shadow-xl w-full mx-auto max-h-screen", customSize || _size)}>
          {" "}
          {/* overflow-auto */}
          <div className="w-full text-start rounded-md">
            <div className="sm:mt-0 rounded-md">
              {!!title && (
                <div className="border-b dark:border-slate-700 ps-3 pt-3 pb-1 rounded-t-md">
                  <div className="flex justify-between w-full items-center">
                    <h3 className="text-sm leading-6 font-medium text-slate-600 dark:text-slate-300 first-letter:uppercase" id="modal-title">
                      {typeof title === "string" ? <span className="capitalize">{String(title).toLocaleLowerCase()}</span> : title}
                    </h3>
                    {onClose && (
                      <button type="button" className="text-slate-400 hover:text-slate-500 dark:hover:text-white px-3 py-2" onClick={onClose}>
                        <FaTimes />
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div className="bg-transparent text-sm text-slate-600 dark:text-slate-300">
                {!!classesWrapper ? <div className={classesWrapper}>{children}</div> : children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="t4-tooltip-modal" className="!text-xs font-serif !bg-slate-800 z-50" />
    </div>,
    document.getElementById("modal")!,
  )
}
