import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"

import type { Door, ResponseDoors, ResponseDoor, DoorFilters, ResponseDoorAvails } from "../DoorType"
import axios from "@axios"
import Normalize from "@/utils/normalize"

export { default as InitState } from "../DoorInitial.state"

export const useDoors = (filters: DoorFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [doors, setDoors] = useState<Door[]>([])
  const [pagination, setPagination] = useState<Pagination>({} as Pagination)

  const fetchAll = async (params: DoorFilters = filters): Promise<void> => {
    try {
      if (filters.terminal_id == "0") delete filters.terminal_id
      setLoading(true)
      const { data: res } = await axios.get<ResponseDoors>(`doorinfo`, { params: Normalize.removePropertiesWithEmptyString(params) })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setDoors(data)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: any): Promise<AxiosResponse<ResponseDoor>> => {
    return await axios.post<ResponseDoor>(`doorinfo`, payload)
  }

  const update = async (payload: any, doorId: number): Promise<AxiosResponse<ResponseDoor>> => {
    return await axios.put<ResponseDoor>(`doorinfo/${doorId}`, payload)
  }

  const findById = async (doorId: number): Promise<AxiosResponse<ResponseDoor>> => {
    return await axios.get<ResponseDoor>(`doorinfo/${doorId}`)
  }

  const destroy = async (doorId: number) => {
    try {
      setLoading(true)
      await axios.delete<ResponseDelete>(`doorinfo/${doorId}`)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const fetchAllAvails = async (terminalId: ModelID, params?: any): Promise<AxiosResponse<ResponseDoorAvails>> => {
    return await axios.get<ResponseDoorAvails>(`door_avail/${terminalId}`, { params })
  }

  return {
    fetchAll,
    fetchAllAvails,
    debounceFetchAll,
    findById,
    doors,
    setDoors,
    pagination,
    setPagination,
    loading,
    setLoading,
    update,
    store,
    destroy,
  }
}
