import { INITIAL_CAMERA } from "@/pages/DispatchPage/components/DispatchMap/DispatchMapInitial.state"
import { InitState as InitStateLoad } from "@pages/Loads/hooks/useLoads"
import { InitState as InitStateTrip } from "@pages/Trips/hooks/useTrips"

import type { TDispatchState } from "@/pages/DispatchPage/DispatchType"
import type { TLoadState } from "@/pages/Loads/LoadType"
import type { TDispatchMapState } from "@/store/AppTypes"

const dispatchMap: TDispatchMapState = {
  coordsCentralPositionMap: INITIAL_CAMERA.center,
}

const load: TLoadState = {
  prevFreightFilters: InitStateLoad.buildFilters(),
  freightFilters: InitStateLoad.filters,
  freights: [],
  freightsPaginated: [],
  currentPage: 1,
  perPages: 150,
  totalPages: 1,
  freightSelected: undefined,
  pagination: {},
}

const dispatch: TDispatchState = {
  prevFiltersLoads: InitStateLoad.filters,
  filtersLoads: InitStateLoad.buildFilters(),
  tripSelected: undefined,
  currentTripSelectedId: 0,
  terminalSelectedId: 0,
  freights: {
    deliveries: [],
    pickups: [],
  },
  doors: [],
  trips: [],
  filtersTrips: InitStateTrip.buildFilters({ per_page: 1000, page: 1 }),
  prevFiltersTrips: InitStateTrip.filters,
}

const app: IAppState = {
  visitedPages: [],
  menuIcons: [],
}

export default {
  dispatchMap,
  dispatch,
  load,
  app,
}
