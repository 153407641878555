import { useContext, useState } from "react"
import { useIntl } from "react-intl"
import { HiChat, HiOutlineX, HiOutlineSearch } from "react-icons/hi"
import clsx from "clsx"
import { FaFilePdf, FaImage, FaCar, FaSpinner } from "react-icons/fa"

import { SocketContext, ContactListStatuses } from "@/context/SocketContext"

import ChatAvatar from "@/components/Chat/ChatAvatar"
import ChatContact from "@/components/Chat/ChatContact"
import { AuthenticationContext } from "@/context/AuthenticateContext"
import type { DriverWithMessage } from "@/context/types"
import { useLocation } from "react-router-dom"

const ChatIndex = (): JSX.Element => {
  const { formatMessage: $t } = useIntl()
  const { contactListStatus, setContactListStatus, driversWithMessages, onlineUsers, contactsSelected, selectContact, user, formatDate, loadingDrivers } =
    useContext(SocketContext)
  const { Auth } = useContext(AuthenticationContext)!
  const [search, setSearch] = useState<string>("")
  const { pathname } = useLocation()

  const chatActives = contactsSelected.reduce<DriverWithMessage[]>((acc, item) => {
    const driver = driversWithMessages.find((d) => d.id === item.id)
    if (driver) acc.push(driver)
    return acc
  }, [])

  const renderMessageFormat = (message: string) => {
    message = message.replace(/(https?:\/\/[^\s]+)/g, `<i style="font-style: italic;">$1</i>`)
    return message
  }

  const driversWithMessagesFiltered = [...driversWithMessages].filter((item) => item.name.includes(search))

  return (
    <>
      {Auth.role.name === "user_admin" && (
        <div className="flex z-50 gap-0.5 fixed bottom-0 right-0 items-end pointer-events-none">
          <div className="flex flex-row-reverse">
            {chatActives.map((driver, index) => (
              <ChatContact key={index} driver={driver} username={String(user.name).toLocaleUpperCase()} />
            ))}
          </div>
          {contactListStatus === ContactListStatuses.List && (
            <div
              className={clsx(
                "w-full sm:w-[300px] bg-white border-s border-b-0 border-t-blue-950 pointer-events-auto dark:border-slate-700 dark:text-slate-300 dark:bg-gray-900",
              )}
            >
              <div className="flex items-center justify-between py-2 px-2 border-b bg-darkblue dark:border-slate-700">
                <div className="leading-[13px] py-1">
                  <h4 className="text-xs text-white font-semibold">{$t({ id: "Drivers" })}</h4>
                </div>
                <button className="text-gray-300 hover:text-white">
                  <HiOutlineX className="text-[16px]" onClick={() => setContactListStatus(ContactListStatuses.Button)} />
                </button>
              </div>
              <div className="flex flex-col h-[calc(100vh-83px)] overflow-auto">
                <div className="relative m-2">
                  <input
                    type="text"
                    placeholder={$t({ id: "Search" })}
                    className="w-full border rounded-2xl py-2 ps-8 outline-none text-sm placeholder:text-gray-400 focus:placeholder:translate-x-1 placeholder:transition-all focus:placeholder:opacity-60 dark:border-slate-700 dark:bg-transparent"
                    value={search}
                    onChange={(ev) => setSearch(String(ev.target.value).toLocaleUpperCase())}
                  />
                  <div className="absolute top-0 h-full content-center px-2.5 pointer-events-none">
                    <HiOutlineSearch className="text-gray-400 " />
                  </div>
                </div>
                {loadingDrivers && (
                  <div className="p-2 text-center text-gray-400">
                    <FaSpinner className="mx-auto animate-spin" />
                    <p className="text-sm mt-1 block">{$t({ id: "Loading" })}...</p>
                  </div>
                )}
                {!loadingDrivers && (
                  <>
                    {driversWithMessagesFiltered.length ? (
                      driversWithMessagesFiltered.map((driver, index) => (
                        <button
                          onClick={() => selectContact(driver)}
                          key={index}
                          className={clsx(
                            "flex items-center gap-2 text-xs hover:bg-slate-100 p-2 text-start border-b last-of-type:border-b-0 dark:border-slate-700 dark:hover:bg-slate-700",
                            !!driver.unreadCount && "bg-slate-100",
                          )}
                        >
                          <div className="relative">
                            <ChatAvatar name={driver.name} active={onlineUsers.some((c) => c.username === driver.name)} />
                          </div>
                          <div className="relative flex-1 overflow-hidden">
                            <p className="text-gray-400 capitalize text-xs">{driver.name}</p>
                            {!!driver.unreadCount && (
                              <span className="text-[9px] font-serif w-4 h-4 bg-red-600 text-white rounded-full flex justify-center items-center absolute right-0 bottom-0">
                                {driver.unreadCount}
                              </span>
                            )}
                            {!!driver.lastMessage && (
                              <>
                                {driver.lastMessage.content === "image" ? (
                                  <p className="flex gap-1 text-sm font-normal py-0.5 px-1 items-center text-gray-400 first-letter:capitalize">
                                    <FaImage className="text-sm" />
                                    {$t({ id: "you action an image" }, { action: $t({ id: driver.lastMessage.type === 0 ? "sent" : "received" }) })}
                                  </p>
                                ) : driver.lastMessage.content === "pdf" ? (
                                  <p className="flex gap-1 text-sm font-normal py-0.5 px-1 items-center text-gray-400 first-letter:capitalize">
                                    <FaFilePdf className="text-sm" />
                                    {$t({ id: "you action a pdf" }, { action: $t({ id: driver.lastMessage.type === 0 ? "sent" : "received" }) })}
                                  </p>
                                ) : (
                                  <p
                                    className="text-sm font-normal py-0.5 text-nowrap overflow-hidden text-ellipsis max-w-[92%]"
                                    dangerouslySetInnerHTML={{ __html: renderMessageFormat(driver.lastMessage.message) }}
                                  ></p>
                                )}
                                <span className="text-gray-400 absolute right-0 top-0 italic text-[10px]">{formatDate(driver.lastMessage.send_at.date)}</span>
                              </>
                            )}
                          </div>
                        </button>
                      ))
                    ) : (
                      <div className="text-gray-400  text-sm p-3">
                        <FaCar className="text-[28px] mx-auto" />
                        <p className="first-letter:capitalize text-center">{$t({ id: "no drivers" })}</p>
                        {!!search && <p className="text-center">{$t({ id: "No matches found for search" }, { search })}</p>}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {contactListStatus === ContactListStatuses.Button && (
            <div className="flex flex-col gap-0.5 m-0.5">
              <button
                className={clsx(
                  "hover:scale-9 bg-[#172554] rounded-full w-10 h-10 min-w-10 min-h-10 flex justify-center items-center",
                  pathname === "/dispatch" ? "invisible pointer-events-none" : "pointer-events-auto",
                )}
                onClick={() => setContactListStatus(ContactListStatuses.List)}
              >
                <HiChat className="text-white text-[20px]" />
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ChatIndex
